import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MainContext } from "../context/MainContext";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    status_nav,
    search_term,
    handleSearch,
    auth_mode,
    is_loading,
    is_mobile,
    show_modal,
    dropdown_account,
  } = useContext(MainContext);
  const [statusNav] = status_nav;
  const [searchTerm] = search_term;
  const [isMobile] = is_mobile;
  const [showModal, setShowModal] = show_modal;
  const [authMode, setAuthMode] = auth_mode;
  const [isLoading, setIsLoading] = is_loading;
  const [showDropdownAccount, setShowDropdownAccount] = dropdown_account;
  let keyTemp = searchTerm.toLowerCase();
  let key = keyTemp.replace(/ /g, "-");

  const handleSubmit = (e) => {
    e.preventDefault();
    searchProduct();
  };

  const navigateTo = (path) => {
    history.push(path);
  };

  const gotoAuth = (mode) => {
    setAuthMode(mode);
    setShowModal(true);
  };

  const searchProduct = () => {
    if (searchTerm !== "") {
      history.push({
        pathname: "/search",
        search: `?q=${key}`,
      });
    }
  };

  const toggleAccount = () => {
    setShowDropdownAccount(!showDropdownAccount);
  };

  const displayAccount = () => {
    const token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    if (token) {
      let username = token.username;
      if (username) {
        return <button className="link-account">{username}</button>;
      }
    }
  };

  const logout = () => {
    if (location.pathname === "/") {
      setIsLoading(true);
      setTimeout(() => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("MemberCode");
        localStorage.removeItem("IdMember");
        setIsLoading(false);
      }, 500);
    } else {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("MemberCode");
      localStorage.removeItem("IdMember");
      history.push("/");
    }
  };

  return (
    <div
      className={`nav_wrap ${statusNav ? "darker" : "transparent"} no-print`}
    >
      <div className="header-scroll">
        <div className="container">
          {isMobile ? (
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ marginTop: "5px" }}>
                <div
                  style={{ cursor: "pointer" }}
                  className="header-logo"
                  onClick={() => navigateTo("/")}
                >
                  <img
                    src="/assets/images/LOGO.png"
                    style={{ height: "30px" }}
                  />
                </div>
              </div>
              <div
                className="header-search-container"
                style={{ marginLeft: "20px" }}
              >
                <form onSubmit={handleSubmit}>
                  <input
                    type="search"
                    name="search"
                    className="search-field"
                    value={searchTerm}
                    placeholder={`Cari barang disini`}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <button
                    className="search-btn"
                    style={{ position: "absolute", top: "22px", right: "6px" }}
                  >
                    <img src="/assets/images/icons/search.svg" height={17} />
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ marginTop: "3px" }}>
                <div
                  style={{ cursor: "pointer" }}
                  className="header-logo"
                  onClick={() => navigateTo("/")}
                >
                  <img
                    src="/assets/images/LOGO.png"
                    style={{ height: "40px" }}
                  />
                </div>
              </div>
              <div
                className="header-search-container"
                style={{ marginLeft: "80px" }}
              >
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="search"
                    className="search-field"
                    value={searchTerm}
                    placeholder={`Cari barang disini`}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <button
                    className="search-btn"
                    style={{ position: "absolute", top: "22px", right: "6px" }}
                  >
                    <img src="/assets/images/icons/search.svg" height={17} />
                  </button>
                </form>
              </div>
            </div>
          )}
          {localStorage.getItem("isLoggedIn") ? (
            <div className="dropdown" onClick={() => toggleAccount()}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evently",
                }}
              >
                <ion-icon
                  name="person-circle"
                  style={{ fontSize: "25px", color: "#EC7825" }}
                ></ion-icon>
                {displayAccount()}
              </div>
              <div
                className={`dropdown-account ${
                  showDropdownAccount ? "active" : ""
                }`}
              >
                <p onClick={() => logout()}>Keluar</p>
              </div>
            </div>
          ) : (
            <div className="header-user-actions">
              <div className="action-btn">
                <div className="btn-auth-wrap">
                  <div
                    title="Masuk"
                    className="btn-auth-register"
                    onClick={() => gotoAuth("signin")}
                  >
                    <p
                      className="font-md"
                      style={{ color: "#fff", fontWeight: "500" }}
                    >
                      Masuk
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import React from 'react'

const AdditionalInfo = (props) => {
    const { el } = props
    return (
        <div className="tab-pane">
            <div className="product-anotherinfo-wrapper">
                <ul>
                    <li><span>Berat</span>{el.berat || "-"}</li>
                    <li><span>Dimensi</span>{el.dimensi || "-"}</li>
                    <li><span>Bahan</span>{el.bahan || "-"}</li>
                    <li><span>Kode Barang</span>{el.sku_no}</li>
                </ul>
            </div>
        </div>
    )
}

export default AdditionalInfo
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { MainContext } from '../context/MainContext'
import { capitalize } from '../Helper'

const Sidebar = () => {
    const dataFetchedRef = useRef(false);
    const history = useHistory()
    const { show_mini_accordion, category_list, getCategory, id_category } = useContext(MainContext)
    const [showMiniAccordion, setShowMiniAccordion] = show_mini_accordion
    const [category] = category_list
    const [idCategory, setIdCategory] = id_category

    const toggle = (index) => {
        if (showMiniAccordion === index) {
            return setShowMiniAccordion(null)
        }
        setShowMiniAccordion(index)
    }

    const navigateTo = (slug, id) => {
        setIdCategory(id)
        history.push(`/category/${slug}`)
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getCategory()
    }, [])

    return (
        <div className="sidebar has-scrollbar">
            <div className="sidebar-category">
                <div className="sidebar-top">
                    <h2 className="sidebar-title">Kategori</h2>
                    <button className="sidebar-close-btn">
                        <ion-icon name="close-outline"></ion-icon>
                    </button>
                </div>
                <ul className="sidebar-menu-category-list">
                    {category.length > 0 && category.map((el, i) => (
                        <li className="sidebar-menu-category" key={i}>
                            <button className="sidebar-accordion-menu" onClick={() => toggle(i)}>
                                <div className="menu-title-flex">
                                    <ion-icon style={{ fontSize: "15px" }} name="grid"></ion-icon>
                                    <p className="menu-title">{capitalize(el.name)}</p>
                                </div>
                                <div>
                                    {showMiniAccordion === i ? (
                                        <ion-icon name="remove-outline" className="remove-icon"></ion-icon>
                                    ) : (
                                        <ion-icon name="add-outline" className="add-icon"></ion-icon>
                                    )}
                                </div>
                            </button>
                            <ul className={`sidebar-submenu-category-list ${showMiniAccordion === i ? "active" : ""}`}>
                                {el.subcategory.length > 0 && el.subcategory.map((item, idx) => {
                                    let nama = (item.name).toLowerCase()
                                    let slug = nama.replace(" ", "-")
                                    return (
                                        <li className="sidebar-submenu-category" style={{ cursor: "pointer" }} key={idx} onClick={() => navigateTo(slug, item.id)}>
                                            <div className="sidebar-submenu-title">
                                                <p className="product-name">{capitalize(item.name)}</p>
                                                {/* <data value="300" className="stock" title="Available Stock">10</data> */}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
            {/* <div className="product-showcase">
                <h3 className="showcase-heading">best sellers</h3>
                <div className="showcase-wrapper">
                    <div className="showcase-container">
                        {bestSeller.length > 0 && bestSeller.map((el, i) => (
                            <div className="showcase" key={i}>
                                <Link to="/" className="showcase-img-box">
                                    <img src={el.img} alt={el.name} width="75" height="75"
                                        className="showcase-img" />
                                </Link>
                                <div className="showcase-content">
                                    <Link to="/">
                                        <h4 className="showcase-title">{el.name}</h4>
                                    </Link>
                                    <div className="showcase-rating">
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                    </div>
                                    <div className="price-box">
                                        <del>{el.old_price}</del>
                                        <p className="price">{el.price}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Sidebar
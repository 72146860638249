import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../context/MainContext";
import { ToastContainer, toast } from "react-toastify";

const host = process.env.REACT_APP_BACKEND;
const ModalAuth = () => {
  const history = useHistory();
  const { show_modal, auth_mode, is_loading } = useContext(MainContext);
  const [showModal, setShowModal] = show_modal;
  const [isLoading, setIsLoading] = is_loading;
  const [showPass, setShowPass] = useState(false);
  const [authMode, setAuthMode] = auth_mode;
  const [credLogin, setCredLogin] = useState({
    IDLogin: "",
    passwordLogin: "",
  });
  const [credRegister, setCredRegister] = useState({
    usernameRegister: "",
    namaRegister: "",
    emailRegister: "",
    passwordRegister: "",
  });

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };

  const collectInfoLogin = (e) => {
    setCredLogin({ ...credLogin, [e.target.name]: e.target.value });
  };

  const collectInfoRegister = (e) => {
    setCredRegister({ ...credRegister, [e.target.name]: e.target.value });
  };

  const submitLogin = (e) => {
    e.preventDefault();

    if (credLogin.IDLogin !== "" && credLogin.passwordLogin !== "") {
      let cred = credLogin.IDLogin.trim();
      let password = credLogin.passwordLogin.trim();
      axios
        .post(`${host}/api/v1/token/`, {
          username: cred,
          password: password,
        })
        .then((res) => {
          toast.success("Login Berhasil", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          if (res.status === 200) {
            const token = res.data;
            axios
              .get(`${host}/api/v1/member_profile/`, {
                headers: { Authorization: `Bearer ${token.access}` },
              })
              .then((res) => {
                localStorage.setItem(
                  "isLoggedIn",
                  JSON.stringify({ ...token, ...res.data.data })
                );
                setTimeout(() => {
                  setShowModal(false);
                  setIsLoading(false);
                }, 1000);
              });
          }
        })
        .catch((err) => {
          if (err) {
            toast.error("Login Gagal", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
        });
    }
  };

  const submitRegister = (e) => {
    e.preventDefault();
  };

  if (authMode === "signin") {
    return (
      <div
        style={{
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.7)",
          position: "fixed",
        }}
      >
        <ToastContainer />
        <div
          style={{
            height: "21rem",
            width: "20rem",
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <div
            style={{
              fontSize: "25px",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setShowModal(false)}
          >
            <ion-icon name="close-circle"></ion-icon>
          </div>
          <div style={{ marginTop: "20px", padding: "0 20px" }}>
            <h3 className="Auth-form-title">Masuk</h3>
            <form onSubmit={submitLogin}>
              <div style={{ marginBottom: "10px" }}>
                <label style={{ fontSize: "15px" }}>Username atau Email</label>
                <div
                  className="pass-auth"
                  style={{ marginTop: "5px", paddingRight: "10px" }}
                >
                  <input
                    type="text"
                    id="IDLogin"
                    className="pass-field"
                    name="IDLogin"
                    value={credLogin.IDLogin}
                    onChange={(e) => collectInfoLogin(e)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label style={{ fontSize: "15px" }}>Password</label>
                <div
                  className="pass-auth"
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    type={`${showPass ? "text" : "password"}`}
                    id="passwordLogin"
                    className="pass-field"
                    name="passwordLogin"
                    value={credLogin.passwordLogin}
                    onChange={(e) => collectInfoLogin(e)}
                  />
                  <div
                    onClick={() => setShowPass(!showPass)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPass ? (
                      <ion-icon
                        name="eye"
                        style={{
                          margin: "0 10px",
                          fontSize: "20px",
                        }}
                      ></ion-icon>
                    ) : (
                      <ion-icon
                        name="eye-off"
                        style={{
                          margin: "0 10px",
                          fontSize: "20px",
                        }}
                      ></ion-icon>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                style={{
                  backgroundColor: "#fd9a43",
                  height: "40px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "20px 0",
                  borderRadius: "10px",
                }}
              >
                <p style={{ fontSize: "15px", fontWeight: "500" }}>Masuk</p>
              </button>
            </form>
          </div>
          {/* <div style={{ textAlign: "center", fontSize: "15px", cursor: "pointer" }}>
                        Belum punya akun?{" "}
                        <span style={{ fontWeight: "700" }} onClick={changeAuthMode}>
                            Daftar
                        </span>
                    </div> */}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
      }}
    >
      <div
        style={{
          height: "34rem",
          width: "20rem",
          padding: "10px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: "25px",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => setShowModal(false)}
        >
          <ion-icon name="close-circle"></ion-icon>
        </div>
        <div style={{ marginTop: "20px", padding: "0 20px" }}>
          <h3 className="Auth-form-title">Daftar</h3>
          <form onSubmit={submitRegister}>
            <div style={{ marginBottom: "10px" }}>
              <label style={{ fontSize: "15px" }}>Username</label>
              <div className="pass-auth" style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  className="pass-field"
                  id="usernameRegister"
                  name="usernameRegister"
                  value={credRegister.usernameRegister}
                  onChange={(e) => collectInfoRegister(e)}
                />
              </div>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label style={{ fontSize: "15px" }}>Nama Lengkap</label>
              <div className="pass-auth" style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  className="pass-field"
                  id="namaRegister"
                  name="namaRegister"
                  value={credRegister.namaRegister}
                  onChange={(e) => collectInfoRegister(e)}
                />
              </div>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label style={{ fontSize: "15px" }}>Email</label>
              <div className="pass-auth" style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  className="pass-field"
                  id="emailRegister"
                  name="emailRegister"
                  value={credRegister.emailRegister}
                  onChange={(e) => collectInfoRegister(e)}
                />
              </div>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label style={{ fontSize: "15px" }}>Password</label>
              <div
                className="pass-auth"
                style={{
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type={`${showPass ? "text" : "password"}`}
                  id="passwordRegister"
                  className="pass-field"
                  name="passwordRegister"
                  value={credRegister.passwordRegister}
                  onChange={(e) => collectInfoRegister(e)}
                />
                <div onClick={() => setShowPass(!showPass)}>
                  {showPass ? (
                    <ion-icon
                      name="eye"
                      style={{ margin: "0 10px", fontSize: "20px" }}
                    ></ion-icon>
                  ) : (
                    <ion-icon
                      name="eye-off"
                      style={{ margin: "0 10px", fontSize: "20px" }}
                    ></ion-icon>
                  )}
                </div>
              </div>
            </div>
            <button
              style={{
                backgroundColor: "#fd9a43",
                height: "40px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px 0",
                borderRadius: "10px",
              }}
            >
              <p style={{ fontSize: "15px", fontWeight: "500" }}>Daftar</p>
            </button>
          </form>
        </div>
        <div
          style={{ textAlign: "center", fontSize: "15px", cursor: "pointer" }}
        >
          Sudah punya akun?{" "}
          <span style={{ fontWeight: "700" }} onClick={changeAuthMode}>
            Masuk
          </span>
        </div>
      </div>
    </div>
  );
};

export default ModalAuth;

import React from "react"
import { TailSpin } from 'react-loader-spinner'

const LoadPage = () => {
    return (
        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <TailSpin color="#EC7825" height={50} width={50} />
        </div>
    )
}
export default React.memo(LoadPage)
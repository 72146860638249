const CardMember = (props) => {
    return (
        <div className="showcase" onClick={() => props.openModalMember(props.data.id)}>
            <div className="showcase-banner" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                <img src="/assets/images/user.png" alt="" width="50" />
            </div>
            <div className="showcase-content-member" style={{ cursor: "pointer" }}>
                <h3 className="showcase-title" style={{ textAlign: "center" }}>{props.data.nama}</h3>
                <p className="showcase-title" style={{ textAlign: "center" }}>{props.data.id_member}</p>
            </div>
        </div>
    )
}

export default CardMember
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

const ModalMember = (props) => {
	const [singleMember, setSingleMember] = useState(props.data)

	const handleChange = (e) => {
		setSingleMember({ ...singleMember, [e.target.name]: e.target.value })
	}

	return (
		<>
			<Modal
				title="Data Member"
				visible={props.isModalVisible}
				onOk={() => props.handleOkModal()}
				onCancel={() => props.handleCancelModal()}
				footer={
					singleMember.is_sync ? (
						[
							<Button key="back" onClick={() => props.handleCancelModal()}>
								Kembali
							</Button>
						]
					) : (
						[
							<Button key="back" onClick={() => props.handleCancelModal()}>
								Batal
							</Button>,
							<Button key="submit" type="primary" onClick={() => props.handleOkModal(singleMember)}>
								Perbarui Data
							</Button>
						]

					)
				}
			>
				<div className="myaccount-info-wrapper">
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>ID Member</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="id_member"
										disabled
										value={singleMember.id_member === null ? "" : singleMember.id_member}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="id_member"
										value={singleMember.id_member === null ? "" : singleMember.id_member}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Kodepos</label>
								{singleMember.is_sync ? (
									<input
										type="tel"
										style={{ backgroundColor: "#ECEFF8" }}
										name="kodepos"
										disabled
										value={singleMember.kodepos === null ? "" : singleMember.kodepos}
									/>
								) : (
									<input
										type="tel"
										style={{ backgroundColor: "#fff" }}
										name="kodepos"
										value={singleMember.kodepos === null ? "" : singleMember.kodepos}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>Official Store</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="os_name"
										disabled
										value={singleMember.os_name === null ? "" : singleMember.os_name}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="os_name"
										value={singleMember.os_name === null ? "" : singleMember.os_name}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Bank</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="bank"
										disabled
										value={singleMember.bank === null ? "" : singleMember.bank}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="bank"
										value={singleMember.bank === null ? "" : singleMember.bank}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>Nama Lengkap</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="nama"
										disabled
										value={singleMember.nama === null ? "" : singleMember.nama}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="nama"
										value={singleMember.nama === null ? "" : singleMember.nama}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Cabang</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="cabang"
										disabled
										value={singleMember.cabang === null ? "" : singleMember.cabang}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="cabang"
										value={singleMember.cabang === null ? "" : singleMember.cabang}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>No KTP</label>
								{singleMember.is_sync ? (
									<input
										type="tel"
										style={{ backgroundColor: "#ECEFF8" }}
										name="no_ktp"
										disabled
										value={singleMember.no_ktp === null ? "" : singleMember.no_ktp}
									/>
								) : (
									<input
										type="tel"
										style={{ backgroundColor: "#fff" }}
										name="no_ktp"
										value={singleMember.no_ktp === null ? "" : singleMember.no_ktp}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Nomor Rekening</label>
								{singleMember.is_sync ? (
									<input
										type="tel"
										style={{ backgroundColor: "#ECEFF8" }}
										name="no_rekening"
										disabled
										value={singleMember.no_rekening === null ? "" : singleMember.no_rekening}
									/>
								) : (
									<input
										type="tel"
										style={{ backgroundColor: "#fff" }}
										name="no_rekening"
										value={singleMember.no_rekening === null ? "" : singleMember.no_rekening}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>Tanggal Lahir</label>
								{singleMember.is_sync ? (
									<input
										type="date"
										style={{ backgroundColor: "#ECEFF8" }}
										name="tgl_lahir"
										disabled
										value={singleMember.tgl_lahir === null ? "" : singleMember.tgl_lahir}
									/>
								) : (
									<input
										type="date"
										style={{ backgroundColor: "#fff" }}
										name="tgl_lahir"
										value={singleMember.tgl_lahir === null ? "" : singleMember.tgl_lahir}
										onChange={(e) => handleChange(e)}
									/>

								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Nama Sesuai Rekening</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="nama_sesuai_rekening"
										disabled
										value={singleMember.nama_sesuai_rekening === null ? "" : singleMember.nama_sesuai_rekening}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="nama_sesuai_rekening"
										value={singleMember.nama_sesuai_rekening === null ? "" : singleMember.nama_sesuai_rekening}
										onChange={(e) => handleChange(e)}
									/>
								)}

							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>No HP</label>
								{singleMember.is_sync ? (
									<input
										type="tel"
										style={{ backgroundColor: "#ECEFF8" }}
										name="no_hp"
										disabled
										value={singleMember.no_hp === null ? "" : singleMember.no_hp}
									/>
								) : (
									<input
										type="tel"
										style={{ backgroundColor: "#fff" }}
										name="no_hp"
										value={singleMember.no_hp === null ? "" : singleMember.no_hp}
										onChange={(e) => handleChange(e)}
									/>

								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Referensi Dari</label>
								<input
									type="text"
									style={{ backgroundColor: "#ECEFF8" }}
									name="referensi dari"
									disabled
									value={singleMember.referensi_dari === null ? "" : singleMember.referensi_dari}
								/>
							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>Email</label>
								{singleMember.is_sync ? (
									<input
										type="text"
										style={{ backgroundColor: "#ECEFF8" }}
										name="email"
										disabled
										value={singleMember.email === null ? "" : singleMember.email}
									/>
								) : (
									<input
										type="text"
										style={{ backgroundColor: "#fff" }}
										name="email"
										value={singleMember.email === null ? "" : singleMember.email}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Nama Pemberi Referensi</label>
								<input
									type="text"
									style={{ backgroundColor: "#ECEFF8" }}
									name="nama_pemberi_referensi"
									disabled
									value={singleMember.nama_pemberi_referensi === null ? "" : singleMember.nama_pemberi_referensi}
								/>
							</div>
						</div>
					</div>
					<div className="form-data">
						<div className="form-input">
							<div className="billing-info">
								<label>No NPWP</label>
								{singleMember.is_sync ? (
									<input
										type="tel"
										style={{ backgroundColor: "#ECEFF8" }}
										name="no_npwp"
										disabled
										value={singleMember.no_npwp === null ? "" : singleMember.no_npwp}
									/>
								) : (
									<input
										type="tel"
										style={{ backgroundColor: "#fff" }}
										name="no_npwp"
										value={singleMember.no_npwp === null ? "" : singleMember.no_npwp}
										onChange={(e) => handleChange(e)}
									/>
								)}
							</div>
						</div>
						<div className="form-input">
							<div className="billing-info">
								<label>Alamat</label>
								{singleMember.is_sync ? (
									<textarea
										rows="5"
										name="alamat"
										value={singleMember.alamat === null ? "" : singleMember.alamat}
										disabled
									></textarea>
								) : (
									<textarea
										rows="5"
										onChange={(e) => handleChange(e)}
										name="alamat"
										style={{ backgroundColor: "#fff" }}
										value={singleMember.alamat === null ? "" : singleMember.alamat}
									></textarea>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default ModalMember
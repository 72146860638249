import React from 'react'

const SVGIcon = (props) => {
    const { fill = 'hsl(0, 0%, 47%)', size = 25 } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={size}
            height={size}
            fill={fill}
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
        </svg>
    )
}

export default SVGIcon
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MainContext } from '../context/MainContext'
import { convertToIDR } from '../Helper'
import Layout from './Layout'
import AdditionalInfo from "../components/AdditionalInfo"
import Review from "../components/Review"
import CarouselImage from '../components/carousel-image'
import ModalPreview from '../components/ModalPreview'

const DetailProduct = () => {
    const dataFetchedRef = useRef(false);
    const location = useLocation()
    const { product_list, search_result, is_loading, getProduct, current_idx } = useContext(MainContext)
    const [product] = product_list
    const [currentIdx, setCurrentIdx] = current_idx
    const [searchResult] = search_result
    const [openPreview, setOpenPreview] = useState(false)
    const [isLoading, setIsLoading] = is_loading
    const [tabActive, setTabActive] = useState("additional_info")
    let loc = location.pathname.split("/")
    let slugProduct = loc[loc.length - 1].replace(/-/gi, "%20")

    const displayStar = (star) => {
        let fulfil = []
        let empty = []
        for (var i = 0; i < parseInt(star); i++) {
            fulfil.push(<ion-icon key={i} name="star"></ion-icon>);
        }
        for (var i = 0; i < (5 - parseInt(star)); i++) {
            empty.push(<ion-icon key={i} name="star-outline"></ion-icon>);
        }
        return (
            <div className="product_star" style={{ zIndex: 9 }}>
                {fulfil}
                {empty}
            </div>
        )
    }

    const changeTab = (menu) => {
        setTabActive(menu)
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setIsLoading(true)
        window.scrollTo(0, 0)
        setCurrentIdx(0)
        if (slugProduct) {
            getProduct(slugProduct, 8, 1)
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }, [])

    return (
        <Layout>
            <section>
                {searchResult.length > 0 ?
                    searchResult.map((el, i) => {
                        if (el.slug === loc[loc.length - 1]) {
                            return (
                                <div className="container" key={i}>
                                    <ModalPreview
                                        openPreview={openPreview}
                                        setOpenPreview={setOpenPreview}
                                        data={el}
                                    />
                                    <div className="flex-row-reverse">
                                        <div className="col-lg-12">
                                            <div className="product-detail accordion-detail">
                                                <div className="detailproduct_wrap">
                                                    <CarouselImage data={el} setOpenPreview={setOpenPreview} />
                                                    <div className="detailinfo_wrap">
                                                        <div className="detail-info">
                                                            <h2 className="title-detail">
                                                                {el.name}
                                                            </h2>
                                                            {displayStar(el.star)}
                                                            <div className="clearfix product-price-cover">
                                                                <div className="product-price primary-color float-left">
                                                                    <ins>
                                                                        <span className="text-brand">
                                                                            {convertToIDR(el.price)}
                                                                        </span>
                                                                    </ins>
                                                                </div>
                                                            </div>
                                                            <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                                                            <div className="short-desc">
                                                                {el.desc}
                                                            </div>
                                                            <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <>
                                                    <div className="description-review-area pb-50">
                                                        <div className="container">
                                                            <div className="description-review-wrapper">
                                                                <div className="description-review-topbar nav">
                                                                    <a className={`${tabActive === "additional_info" ? "active" : ""}`} onClick={() => changeTab("additional_info")}>Detail</a>
                                                                    <a className={`${tabActive === "review" ? "active" : ""}`} onClick={() => changeTab("review")}>Ulasan</a>
                                                                </div>
                                                                <div className="tab-content description-review-bottom">
                                                                    {tabActive === "additional_info" ? (
                                                                        <AdditionalInfo kode={el.sku_no} />
                                                                    ) : tabActive === "review" ? (
                                                                        <Review />
                                                                    ) : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                    :
                    product.map((el, i) => {
                        if (el.slug === loc[loc.length - 1]) {
                            return (
                                <div className="container" key={i}>
                                    <ModalPreview
                                        openPreview={openPreview}
                                        setOpenPreview={setOpenPreview}
                                        data={el}
                                    />
                                    <div className="flex-row-reverse">
                                        <div className="col-lg-12">
                                            <div className="product-detail accordion-detail">
                                                <div className="detailproduct_wrap">
                                                    <CarouselImage data={el} setOpenPreview={setOpenPreview} />
                                                    <div className="detailinfo_wrap">
                                                        <div className="detail-info">
                                                            <h2 className="title-detail">
                                                                {el.name}
                                                            </h2>
                                                            <div style={{ padding: "5px 10px", height: "auto", width: "120px", backgroundColor: "rgb(246, 163, 85)" }}>
                                                                {el.subcategory_name}
                                                            </div>
                                                            {displayStar(el.star)}
                                                            <div className="clearfix product-price-cover">
                                                                <div className="product-price primary-color float-left">
                                                                    <ins>
                                                                        <span className="text-brand">
                                                                            {convertToIDR(el.price)}
                                                                        </span>
                                                                    </ins>
                                                                </div>
                                                            </div>
                                                            <div className="bt-1 border-color-1 mt-15"></div>
                                                            <div className="short-desc">
                                                                {el.desc}
                                                            </div>
                                                            <div className="bt-1 border-color-1 mt-10 mb-30"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <>
                                                    <div className="description-review-area pb-50">
                                                        <div className="container">
                                                            <div className="description-review-wrapper">
                                                                <div className="description-review-topbar nav">
                                                                    <a className={`${tabActive === "additional_info" ? "active" : ""}`} onClick={() => changeTab("additional_info")}>Detail</a>
                                                                    <a className={`${tabActive === "review" ? "active" : ""}`} onClick={() => changeTab("review")}>Ulasan</a>
                                                                </div>
                                                                <div className="tab-content description-review-bottom">
                                                                    {tabActive === "additional_info" ? (
                                                                        <AdditionalInfo el={el} />
                                                                    ) : tabActive === "review" ? (
                                                                        <Review />
                                                                    ) : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </section>
            <div className="clearfix"></div>
        </Layout>
    )
}

export default React.memo(DetailProduct)
import { Button, Card, Row, Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { MainContext } from "../context/MainContext";
import { convertToIDR } from "../Helper";
import ModalHistory from "./ModalHistory";
import ModalHistoryMobile from "./ModalHistoryMobile";
import SearchComp from "./SearchComp";

const HistoryPOSAdmin = (props) => {
  const { data_history_admin } = useContext(MainContext);
  const [dataHistoryAdmin] = data_history_admin;
  const [showModal, setShowModal] = useState(false);
  const [showModalMobile, setShowModalMobile] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [dataModalNama, setDataModalNama] = useState("");
  const [dataModalNamaOS, setDataModalNamaOS] = useState("");
  const [dataModalStatus, setDataModalStatus] = useState("");
  const [dataModalTglBeli, setDataModalTglBeli] = useState("");
  const [dataModalGrandTotal, setDataModalGrandTotal] = useState("");
  const [dataModalGrandTotalBonus, setDataModalGrandTotalBonus] = useState("");
  const [cartId, setCartId] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    props.getHistory(pagination.current, pagination.pageSize);
  };

  const displayTag = (tag) => {
    switch (tag) {
      case "true":
        return <Tag color="green">lunas</Tag>;
      case "false":
        return <Tag color="red">Gagal</Tag>;
      default:
        break;
    }
  };
  const columnsDesktop = [
    {
      key: "nama",
      title: "Nama Member",
      dataIndex: "member_data",
      render: (obj) => obj.nama,
      width: "20%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "nama_os",
      title: "Nama OS",
      dataIndex: "os_data",
      render: (obj) => obj.nama,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "grandtotal",
      title: "GrandTotal",
      dataIndex: "grandtotal",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "grandtotal_bonus",
      title: "GrandTotal Bonus",
      dataIndex: "grandtotal_bonus",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "is_lunas",
      render: (obj) => displayTag(obj.toString()),
      width: "10%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "tgl_beli",
      title: "Tgl Pembelian",
      dataIndex: "modified_date",
      render: (obj) => moment(obj).format("D MMMM YYYY HH:mm A"),
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "aksi",
      title: "Aksi",
      dataIndex: "cart_item",
      render: (obj, el) => (
        <Tooltip placement="bottomRight" title={"Lihat detail"}>
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDataModal(obj);
              setCartId(el.cart_id);
              setDataModalNama(el.member_data.nama);
              setDataModalNamaOS(el.member_data.os_name);
              setDataModalStatus(el.isLunas ? "Lunas" : "Belum Lunas");
              setDataModalTglBeli(
                el.modified_date &&
                  moment(el.modified_date).format("D MMMM YYYY HH:mm A")
              );
              setDataModalGrandTotal(convertToIDR(el.grandtotal));
              setDataModalGrandTotalBonus(convertToIDR(el.grandtotal_bonus));
              setShowModal(true);
            }}
          >
            Detail
          </Button>
        </Tooltip>
      ),
      width: "5%",
      sorter: false,
      sortOrder: null,
    },
  ];

  const displayData = (title, content, color) => {
    return (
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography.Text>{title} :</Typography.Text>
        <Typography.Text style={{ fontWeight: 600, color: color }}>
          {content}
        </Typography.Text>
      </Row>
    );
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h2 className="title">Riwayat Penjualan</h2>
      </div>
      <SearchComp
        searchTerm={props.searchHistory}
        determineKeySearch={props.handleSearchHistory}
      />
      {isMobile ? (
        <Row span={24}>
          {dataHistoryAdmin?.length > 0 ? (
            dataHistoryAdmin?.map((el, i) => (
              <Card
                key={i}
                style={{ width: "100%", color: "#EC7825" }}
                type="inner"
                title={`SO00${el.cart_id}`}
                extra={
                  <p
                    onClick={() => {
                      setDataModal(el.cart_item);
                      setDataModalNama(el.member_data.nama);
                      setDataModalNamaOS(el.member_data.os_name);
                      setDataModalStatus(el.isLunas ? "Lunas" : "Belum Lunas");
                      setDataModalTglBeli(
                        el.modified_date &&
                          moment(el.modified_date).format("D MMMM YYYY HH:mm A")
                      );
                      setDataModalGrandTotal(convertToIDR(el.grandtotal));
                      setDataModalGrandTotalBonus(
                        convertToIDR(el.grandtotal_bonus)
                      );
                      setCartId(el.cart_id);
                      setShowModalMobile(true);
                    }}
                    style={{ color: "#EC7825", fontWeight: 500 }}
                  >
                    Detail
                  </p>
                }
              >
                {displayData("Nama Pembeli", el.member_data.nama, "#457b9d")}
                {displayData("Official Store", el.os_data.nama, "#457b9d")}
                {displayData(
                  "Grandtotal",
                  convertToIDR(el.grandtotal),
                  "#90323d"
                )}
                {displayData(
                  "Grandtotal Bonus",
                  convertToIDR(el.grandtotal_bonus),
                  "#90323d"
                )}
                {displayData(
                  "Status Pembayaran",
                  el.is_lunas ? "Lunas" : "Belum Lunas",
                  "#538d22"
                )}
                {displayData(
                  "Tanggal",
                  el.modified_date &&
                    moment(el.modified_date).format("D MMMM YYYY HH:mm A"),
                  "#457b9d"
                )}
              </Card>
            ))
          ) : (
            <div
              style={{
                height: "30rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/images/icons/empty-history.png"
                height={100}
                style={{ marginBottom: "20px" }}
              />
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                Belum ada Riwayat Penjualan
              </p>
            </div>
          )}
        </Row>
      ) : (
        <Table
          sorter={false}
          sortOrder="null"
          columns={columnsDesktop}
          rowKey="cart_id"
          dataSource={dataHistoryAdmin}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      )}
      <ModalHistory
        data={dataModal}
        cartId={cartId}
        isVisible={showModal}
        onCancel={() => setShowModal(false)}
        tglBeli={dataModalTglBeli}
        grandTotal={dataModalGrandTotal}
        grandTotalBonus={dataModalGrandTotalBonus}
        nama={dataModalNama}
        namaOS={dataModalNamaOS}
        status={dataModalStatus}
      />
      <ModalHistoryMobile
        data={dataModal}
        cartId={cartId}
        isVisible={showModalMobile}
        onCancel={() => setShowModalMobile(false)}
        tglBeli={dataModalTglBeli}
        grandTotal={dataModalGrandTotal}
        grandTotalBonus={dataModalGrandTotalBonus}
        nama={dataModalNama}
        namaOS={dataModalNamaOS}
        status={dataModalStatus}
      />
    </>
  );
};

export default HistoryPOSAdmin;

import React from "react";

const SVGIcon = (props) => {
  const { fill = "#9e2a2b", size = 18 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm2-2v2h6V4H9z" />
    </svg>
  );
};

export default SVGIcon;

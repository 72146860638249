import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductGrid from "../components/ProductGrid";
import { MainContext } from "../context/MainContext";
import { capitalize } from "../Helper";
import Layout from "./Layout";
import ErrorPage from "./ErrorPage";

const host = process.env.REACT_APP_BACKEND;
const SearchPage = () => {
  const location = useLocation();
  const { is_loading } = useContext(MainContext);
  const [, setIsLoading] = is_loading;
  const [product, setProduct] = useState([]);
  const loc = location.search.split("=");
  const locTemp = loc[loc.length - 1];
  const key = locTemp.replace(/-/gi, " ");

  const getSearchData = () => {
    setProduct([]);
    axios.get(`${host}/api/v1/product/?search=${key}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setProduct(res.data);
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getSearchData();
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [location]);

  if (product.length > 0) {
    return (
      <Layout>
        <div className="product-container">
          <div className="container">
            <ProductGrid
              isCatalogCategory
              path={location.pathname}
              title={`Hasil pencarian untuk "${capitalize(key)}"`}
              product={product}
            />
          </div>
        </div>
      </Layout>
    );
  } else {
    return <ErrorPage />;
  }
};

export default SearchPage;

import { Button, Col, Grid, Row, Table } from "antd";
import moment from "moment";
import { convertToIDR } from "../Helper";
import { PrintIcon } from "./icons";

const { useBreakpoint } = Grid;

const ModalBonus = (props) => {
  const { lg } = useBreakpoint();
  const { data } = props;

  const fields1 = {
    "Member Code": data.MemberCode ? data.MemberCode : "-",
    "Member Name": data.MemberName ? data.MemberName : "-",
    Email: data.EMail ? data.EMail : "-",
  };
  const fields2 = {
    "Sponsor Code": data.SponsorCode ? data.SponsorCode : "-",
    "Sponsor Name": data.SponsorName ? data.SponsorName : "-",
  };
  const fields3 = {
    "Downline Langsung": data.CountDirect ? data.CountDirect : 0,
    "Grade / Peringkat": data.GradeFullName ? data.GradeFullName : "-",
    "Rekrute baru (KIT)": data.NewDirectKit ? data.NewDirectKit : 0,
  };
  const fields4 = {
    "Baru Aktif": data.TotAktifDwlnNew ? data.TotAktifDwlnNew : 0,
    "Total Aktif": data.TotAktifDwln ? data.TotAktifDwln : 0,
  };
  const fields5 = {
    "Total Akumulasi": data.LastTotAccumulation
      ? convertToIDR(data.LastTotAccumulation)
      : 0,
    "OS get OS (+)": data.Adjustment
      ? convertToIDR(parseInt(data.Adjustment))
      : 0,
    "Potongan Pajak (-)": data.TaxAmount
      ? convertToIDR(parseInt(data.TaxAmount))
      : 0,
    "AdminFee / Bank (-)": data.AdminFee
      ? convertToIDR(parseInt(data.AdminFee))
      : 0,
    "Total Net Bonus": data.BnsNetAmt
      ? convertToIDR(parseInt(data.BnsNetAmt))
      : 0,
    Keterangan: data.PaidType ? data.PaidType : "-",
  };

  const columns1 = [
    {
      title: "Keterangan & Total",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Harga Katalog",
      dataIndex: "hargaKatalog",
      key: "hargaKatalog",
      render: (text) => <p>{convertToIDR(text)}</p>,
    },
    {
      title: "Harga Bonus",
      dataIndex: "hargaBonus",
      key: "hargaBonus",
      render: (text) => (
        <p>{typeof text === "number" ? convertToIDR(text) : null}</p>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Networking & Total",
      dataIndex: "generasi",
      key: "generasi",
      render: (text) => <p>Generasi {text}</p>,
    },
    {
      title: "Harga Katalog",
      dataIndex: "harga_catalog",
      key: "harga_catalog",
      render: (text) => <p>{convertToIDR(text)}</p>,
    },
    {
      title: "Harga Bonus",
      dataIndex: "harga_bonus",
      key: "harga_bonus",
      render: (text) => <p>{convertToIDR(text)}</p>,
    },
    {
      title: "Nilai Bonus",
      dataIndex: "nilai_bonus",
      key: "nilai_bonus",
      render: (text) => <p>{convertToIDR(text)}</p>,
    },
  ];
  const columns3 = [
    {
      title: "Type-Type Bonus",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <p
          style={{
            fontWeight: text === "Total Bonus" ? 700 : 400,
            fontSize: text === "Total Bonus" ? "16px" : "unset",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Jumlah",
      dataIndex: "jumlah",
      key: "jumlah",
      render: (text, record) => (
        <p
          style={{
            fontWeight: record?.title === "Total Bonus" ? 700 : 400,
            fontSize: record?.title === "Total Bonus" ? "16px" : "unset",
          }}
        >
          {convertToIDR(text)}
        </p>
      ),
    },
  ];
  const columns4 = [
    {
      title: "Member Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Member Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Grade/Posisi",
      dataIndex: "grade",
      key: "grade",
    },
  ];

  const data1 = [
    {
      key: "1",
      title: "Pembelanjaan Pribadi",
      hargaKatalog: data.PoHK ? data.PoHK : 0,
      hargaBonus: data.PoHB ? data.PoHB : 0,
    },
    {
      key: "2",
      title: "Group Non Peringkat [M-1]",
      hargaKatalog: data.GoHK_NP ? data.GoHK_NP : 0,
      hargaBonus: "",
    },
    {
      key: "3",
      title: "Akumulasi Group Bulan ini",
      hargaKatalog: data.AGoHK ? data.AGoHK : 0,
      hargaBonus: "",
    },
  ];
  const data3 = [
    {
      key: "1",
      title: "Bonus Blj Pribadi",
      jumlah: data.Bns_BljPribadi ? data.Bns_BljPribadi : "-",
    },
    {
      key: "2",
      title: "Bonus Rekrute",
      jumlah: data.Bns_Rekrut ? data.Bns_Rekrut : "-",
    },
    {
      key: "3",
      title: "Bonus Matahari",
      jumlah: data.Bns_Matahari ? data.Bns_Matahari : "-",
    },
    {
      key: "4",
      title: "Bonus Networking",
      jumlah: data.Bns_Networking ? data.Bns_Networking : "-",
    },
    {
      key: "5",
      title: "Total Bonus",
      jumlah: data.Total ? data.Total : "-",
    },
  ];

  return (
    <>
      <Row style={{ flexDirection: lg ? "row" : "column" }}>
        <Col span={10}>
          <h1
            style={{
              fontSize: "22px",
              fontWeight: 500,
              lineHeight: lg ? "30px" : "30px",
            }}
          >
            SHEENA MARVIN
          </h1>
          <h1 style={{ fontSize: "15px", fontWeight: 500, marginTop: "-15px" }}>
            Jalan Gempol Raya No 3B
          </h1>
          <p style={{ fontSize: "15px", marginTop: "-12px" }}>
            Cipayung - Jakarta Timur
          </p>
        </Col>
        <Col span={10}>
          <h1 style={{ fontSize: "18px", fontWeight: 500 }}>
            {data.MemberName ? data.MemberName : "-"}
          </h1>
          <p style={{ fontSize: "15px", marginTop: "-12px" }}>
            {data.Address1}
          </p>
          <p style={{ fontSize: "15px", marginTop: "-12px" }}>
            {data.Address2}
          </p>
          <p style={{ fontSize: "15px", marginTop: "-12px" }}>
            Phone: {data.HandPhone ? data.HandPhone : "-"}
          </p>
        </Col>
        <Col span={4} className="no-print">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                height: "35px",
                width: "90px",
                fontSize: "15.5px",
                display: "flex",
                width: "110px",
                alignItems: "center",
              }}
              onClick={() => window.print()}
              type="primary"
            >
              <PrintIcon /> <span style={{ marginLeft: "10px" }}>Cetak</span>
            </Button>
          </div>
        </Col>
      </Row>
      <div
        style={{
          background: "#f1c40f",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0 30px 0",
        }}
      >
        <h1 style={{ fontSize: "22px", fontWeight: 500 }}>STATEMENT BONUS</h1>
        <p style={{ fontSize: "16px", marginTop: "-15px" }}>
          Periode :{" "}
          <span style={{ fontWeight: 700 }}>
            {data.Periode && moment(data.Periode).format("MMMM YYYY")}
          </span>
        </p>
      </div>
      <Row style={{ flexDirection: lg ? "row" : "column" }}>
        <Col span={lg ? 12 : 24}>
          {Object.entries(fields1).map((el, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-10px",
              }}
            >
              <p style={{ width: "35%", fontSize: "15px" }}>{el[0]}</p>
              <p style={{ marginRight: "20px", fontSize: "15px" }}>:</p>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: el[0] === "Email" ? 400 : 600,
                }}
              >
                {el[1]}
              </p>
            </div>
          ))}
        </Col>
        <Col span={lg ? 12 : 24}>
          {Object.entries(fields2).map((el, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-10px",
              }}
            >
              <p style={{ width: "35%", fontSize: "15px" }}>{el[0]}</p>
              <p style={{ marginRight: "20px", fontSize: "15px" }}>:</p>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: 600,
                }}
              >
                {el[1]}
              </p>
            </div>
          ))}
        </Col>
      </Row>
      <div>
        <Table
          className="table-bonus"
          rowKey="id"
          bordered
          columns={columns1}
          dataSource={data1}
          pagination={false}
        />
      </div>
      <Row style={{ margin: "30px 0 0", flexDirection: lg ? "row" : "column" }}>
        <Col span={lg ? 12 : 24}>
          {Object.entries(fields3).map((el, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-10px",
              }}
            >
              <p style={{ width: "35%", fontSize: "15px" }}>{el[0]}</p>
              <p style={{ marginRight: "20px", fontSize: "15px" }}>:</p>
              <p
                style={{
                  fontSize: "15px",
                }}
              >
                {el[1]}
              </p>
            </div>
          ))}
        </Col>
        <Col span={lg ? 12 : 24}>
          {Object.entries(fields4).map((el, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-10px",
              }}
            >
              <p style={{ width: "35%", fontSize: "15px" }}>{el[0]}</p>
              <p style={{ marginRight: "20px", fontSize: "15px" }}>:</p>
              <p
                style={{
                  fontSize: "15px",
                }}
              >
                {el[1]}
              </p>
            </div>
          ))}
        </Col>
      </Row>
      {data?.networking?.length ? (
        <div style={{ margin: "10px 0" }}>
          <Table
            className="table-bonus"
            rowKey="id"
            bordered
            columns={columns2}
            dataSource={data?.networking}
            pagination={false}
          />
        </div>
      ) : null}
      <Row style={{ margin: "20px 0" }} className="page-break">
        <Col span={lg ? 12 : 24}>
          <Table
            className="table-bonus"
            rowKey="id"
            bordered
            columns={columns3}
            dataSource={data3}
            pagination={false}
          />
        </Col>
        <Col
          span={lg ? 11 : 24}
          style={{
            border: "1px solid #000",
            marginLeft: lg ? "20px" : "unset",
            padding: "20px",
          }}
        >
          {Object.entries(fields5).map((el, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-10px",
              }}
            >
              <p
                style={{
                  width: "35%",
                  fontSize: "15px",
                  fontWeight: el[0] === "Total Net Bonus" ? 700 : 400,
                }}
              >
                {el[0]}
              </p>
              <p style={{ marginRight: "20px", fontSize: "15px" }}>:</p>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: el[0] === "Total Net Bonus" ? 700 : 400,
                }}
              >
                {el[1]}
              </p>
            </div>
          ))}
        </Col>
      </Row>
      <Row
        justify="center"
        align="center"
        style={{ border: "1px solid #000", padding: "10px" }}
      >
        Bank : {data.BankCode ? data.BankCode : "-"} No.Rek. :{" "}
        {data.BankAccount ? data.BankAccount : "-"} a/n :{" "}
        {data.BankAccountName ? data.BankAccountName : "-"}
      </Row>
      {data?.downlines?.length ? (
        <div style={{ margin: "10px 0" }} className="page-break">
          <p style={{ fontStyle: "italic", margin: "10px 0 0" }}>
            List 10 Downline Langsung berdasarkan peringkat tertinggi
          </p>
          <Table
            className="table-bonus"
            rowKey="id"
            bordered
            columns={columns4}
            dataSource={data?.downlines}
            pagination={false}
          />
        </div>
      ) : null}
      <div
        style={{
          background: "#f1c40f",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0 20px 0",
          textAlign: "center",
          padding: "10px",
        }}
      >
        <div>
          <span style={{ fontSize: "16px", fontWeight: 600 }}>
            &quot;Selamat Bergabung di SHEENA MARVIN&quot;,
          </span>{" "}
          <span style={{ fontSize: "16px", fontStyle: "italic" }}>
            Bonus Blj Pribadi + Rekrut + Matahari + Networking {"<"} Rp 50.000,-
          </span>
        </div>
        <div>
          <span
            style={{
              fontSize: "15px",
              fontStyle: "italic",
            }}
          >
            menjadi Bonus Akumulasi,
          </span>{" "}
          <span
            style={{
              marginRight: "10px",
              fontSize: "15px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            Per {data.Periode && moment(data.Periode).format("MMMM YYYY")} bonus
            yang dibayar dipotong pajak.
          </span>
        </div>
        <div>
          <span
            style={{
              marginRight: "10px",
              fontSize: "15px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            NPWP No : {data.TaxID ? data.TaxID : "-"} a/n{" "}
          </span>{" "}
          <span
            style={{
              marginRight: "10px",
              fontSize: "16px",
            }}
          >
            {data.MemberName ? data.MemberName : "-"}
          </span>{" "}
          <span
            style={{
              fontSize: "15px",
              fontStyle: "italic",
              fontWeight: 600,
            }}
          >
            UniqueIncome : {data.UniqueIncome ? data.UniqueIncome : "-"}
          </span>
        </div>
      </div>
    </>
  );
};

export default ModalBonus;

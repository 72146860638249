import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MainContext } from '../context/MainContext'
import Layout from './Layout'

const ErrorPage = () => {
    const { is_mobile } = useContext(MainContext)
    const [isMobile] = is_mobile

    return (
        <Layout>
            <main className="main page-404">
                <div className="container">
                    {isMobile ? (
                        <div className="notfound_wrap_mobile">
                            <div className="col-lg-8 m-auto">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                                    <img
                                        src="assets/images/404.png"
                                        alt="404 Image"
                                        className="hover-up"
                                    />
                                </div>
                                <h2 className="mb-30" style={{ fontSize: "16px" }}>Halaman Tidak Ditemukan</h2>
                                <p className="text-grey-700 font-md mb-30 text_notfound" style={{ fontSize: "14px" }}>
                                    Tautan yang Anda klik mungkin rusak atau halaman telah dihapus.
                                </p>
                                <Link to="/product">
                                    <div className="btn btn-default submit-auto-width hover-up">
                                        <p style={{ fontSize: "14px" }}>Kembali ke Halaman Produk</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="notfound_wrap">
                            <div className="col-lg-8 m-auto" style={{ marginTop: "-50px" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                                    <img
                                        src="assets/images/404.png"
                                        alt="404 Image"
                                        className="hover-up"
                                    />
                                </div>
                                <h2 className="mb-30" style={{ fontSize: "20px" }}>Halaman Tidak Ditemukan</h2>
                                <p className="text-grey-700 font-md mb-30 text_notfound">
                                    Tautan yang Anda klik mungkin rusak atau halaman telah dihapus.
                                </p>
                                <Link to="/product">
                                    <div className="btn btn-default submit-auto-width font-xs hover-up">
                                        <p>Kembali ke Halaman Produk</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </Layout>
    )
}

export default React.memo(ErrorPage)
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import React from "react";
import { convertToIDR } from "../Helper";

const styles = StyleSheet.create({
  table: {
    width: "90%",
    margin: "0 20px",
    borderBottom: "1px solid #DEDEDE",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
  },
  title: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#9b9b9b",
    margin: "0 0 10px 20px",
  },
  text: {
    fontSize: "12px",
    margin: "0 20px 10px 20px",
  },
  textHeader: {
    fontSize: "11px",
    margin: "0 20px 10px 20px",
  },
  header: {
    borderTop: "none",
    borderBottom: "1px solid #DEDEDE",
  },
  head: {
    margin: "0 2px",
  },
  bold: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  content: {
    fontSize: "12.5px",
    color: "#9b9b9b",
    margin: "0 2px",
  },
  row1: {
    width: "30%",
  },
  row2: {
    width: "10%",
  },
  row3: {
    width: "15%",
  },
  row4: {
    width: "15%",
  },
  row5: {
    width: "15%",
  },
  row6: {
    width: "15%",
  },
  bottomWrap: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    margin: "20px 20px 0 0",
  },
  grandtotal: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#9b9b9b",
    margin: "5px 0",
  },
  bottomContent: {
    padding: "10px",
    border: "1px solid #EEE",
  },
});

const Invoice = (props) => {
  return (
    <Document>
      <Page>
        <Image
          src="/assets/images/LOGO.png"
          style={{
            height: "40px",
            width: "113px",
            margin: "30px 20px 30px 20px",
          }}
        />
        <Text style={styles.title}>INVOICE</Text>
        <Text style={styles.textHeader}>Nama Pembeli : {props.nama}</Text>
        <Text style={styles.textHeader}>Nama OS : {props.namaOS}</Text>
        <Text style={styles.textHeader}>
          Tanggal Pembelian : {props.tglBeli}
        </Text>
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={[styles.head, styles.row1, styles.bold]}>
              Nama Produk
            </Text>
            <Text style={[styles.head, styles.row2, styles.bold]}>Qty</Text>
            <Text style={[styles.head, styles.row3, styles.bold]}>Harga</Text>
            <Text style={[styles.head, styles.row4, styles.bold]}>
              Harga Bonus
            </Text>
            <Text style={[styles.head, styles.row5, styles.bold]}>
              Subtotal
            </Text>
            <Text style={[styles.head, styles.row6, styles.bold]}>
              Subtotal Bonus
            </Text>
          </View>
          {props.data.map((row, i) => (
            <View key={i} style={styles.row} wrap={false}>
              <Text style={[styles.row1, styles.content]}>
                {row.product_name}
              </Text>
              <Text style={[styles.row2, styles.content]}>{row.qty}</Text>
              <Text style={[styles.row3, styles.content]}>
                {convertToIDR(row.product_price)}
              </Text>
              <Text style={[styles.row4, styles.content]}>
                {convertToIDR(row.product_price_bonus)}
              </Text>
              <Text style={[styles.row5, styles.content]}>
                {convertToIDR(row.total)}
              </Text>
              <Text style={[styles.row6, styles.content]}>
                {convertToIDR(row.total_bonus)}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.bottomWrap}>
          <View style={styles.bottomContent}>
            <Text style={styles.grandtotal}>
              GrandTotal : <Text>{props.grandTotal}</Text>
            </Text>
            <Text style={styles.grandtotal}>
              GrandTotal Bonus : <Text>{props.grandTotalBonus}</Text>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;

import { Card } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadOfficialStore from "../components/LoadOfficialStore";
import { MainContext } from "../context/MainContext";
import { capitalize } from "../Helper";
import Layout from "./Layout";
const { Meta } = Card;

const host = process.env.REACT_APP_BACKEND;
const OfficialStore = () => {
  const [loadData, setLoadData] = useState(false);
  const [empty, setEmpty] = useState(false);
  const location = useLocation();
  const { provinsi_list, os } = useContext(MainContext);
  const [provinsi] = provinsi_list;
  const [OS, setOS] = os;
  let loc = location.pathname.split("/");
  let OSSlug = loc[loc.length - 1].replace(/-/gi, "%20");
  let OSDisplay = loc[loc.length - 1].replace(/-/gi, " ");

  const handleOnClick = (slug) => {
    let OSSlug = slug.replace("-", "%20");
    getOS(OSSlug);
  };

  const getOS = (slug) => {
    let id = 0;
    axios.get(`${host}/api/v1/kota/?search=${slug}`).then((res) => {
      if (res.status === 200) {
        let data = res.data;
        if (data.length > 0) {
          let tmp = slug.replace(/%20/g, "-");
          data.map((el) => {
            if (el.slug === tmp) {
              id = el.id;
            }
          });
        }
        if (id !== 0) {
          getOSBySlug(id);
        }
      }
    });
  };

  const getOSBySlug = async (id) => {
    let tmp = [];
    await axios.get(`${host}/api/v1/official_store/`).then((res) => {
      if (res.status === 200) {
        let data = res.data;
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].kota === id) {
              tmp.push(data[i]);
            }
          }
        }
        if (tmp.length !== 0) {
          setOS(tmp);
        } else {
          setOS([]);
          setEmpty(true);
        }
        setLoadData(false);
      }
    });
  };

  useEffect(() => {
    setLoadData(true);
    getOS(OSSlug);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout>
      <section className="official_wrap">
        <div className="container">
          <h2 className="title">Official Store {OSDisplay}</h2>
          <div className="os_main">
            <div className="os_selection">
              <div className="row">
                <div className="col-md-4">
                  <div className="dashboard-menu">
                    {provinsi.length > 0 &&
                      provinsi.map((el, i) => {
                        if (el.slug === loc[loc.length - 2]) {
                          return (
                            <ul
                              className="nav"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              role="tablist"
                              key={i}
                            >
                              {el.kota.length > 0 &&
                                el.kota.map((item, index) => {
                                  if (item.slug === loc[loc.length - 1]) {
                                    return (
                                      <li className="nav-item" key={index}>
                                        <Link
                                          className={"nav-link active"}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                          to={`/officialstore/${
                                            loc[loc.length - 2]
                                          }/${item.slug}`}
                                        >
                                          <img
                                            src="/assets/images/icons/location.svg"
                                            height={17}
                                          />
                                          <p style={{ marginLeft: "10px" }}>
                                            {capitalize(item.nama)}
                                          </p>
                                        </Link>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className="nav-item"
                                        onClick={() => handleOnClick(item.slug)}
                                        key={index}
                                      >
                                        <Link
                                          className={"nav-link"}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                          to={`/officialstore/${
                                            loc[loc.length - 2]
                                          }/${item.slug}`}
                                        >
                                          <img
                                            src="/assets/images/icons/location.svg"
                                            height={17}
                                          />
                                          <p style={{ marginLeft: "10px" }}>
                                            {capitalize(item.nama)}
                                          </p>
                                        </Link>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
            {loadData ? (
              <LoadOfficialStore loadData={loadData} />
            ) : OS.length === 0 && empty ? (
              <div
                style={{
                  width: "100%",
                  height: "20rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/images/icons/empty.png"
                    height={100}
                    style={{ marginBottom: "20px" }}
                  />
                  <p style={{ fontSize: "15px", fontWeight: "600" }}>
                    Data Official Store Tidak Ditemukan
                  </p>
                </div>
              </div>
            ) : (
              <div className="os_wrap">
                {OS.length > 0 &&
                  OS.map((el, i) => (
                    <Card
                      key={i}
                      cover={
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#ECEFF8",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                          }}
                        >
                          <img
                            style={{ height: "150px", width: "200px" }}
                            alt={el.nama}
                            src={el.image}
                          />
                          {el.youtube !== "" && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                backgroundColor: "#fe4c4c",
                                borderRadius: "5px",
                                padding: "5px",
                              }}
                            >
                              <a
                                className="video-popup"
                                href={el.youtube}
                                target="_blank"
                              >
                                <img
                                  src="/assets/images/icons/logo-youtube.svg"
                                  height={20}
                                />
                              </a>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <Meta
                        title={capitalize(el.nama)}
                        description={capitalize(el.alamat)}
                      />
                    </Card>
                  ))}
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="clearfix"></div>
    </Layout>
  );
};

export default OfficialStore;

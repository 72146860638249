import { Button, Pagination, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MainContext } from "../context/MainContext";
import { isMobile as isMobileView } from 'react-device-detect';
import { convertToIDR } from "../Helper";

const ProductGrid = (props) => {
  const dataFetchedRef = useRef(false);
  const location = useLocation();
  const {
    getSubCategory,
    sub_category,
    is_mobile,
    product_length,
    getProduct,
    getCatalogData,
    getCategoryData,
    id_catalog,
    id_category,
  } = useContext(MainContext);
  const [subCategory] = sub_category;
  const [idCatalog] = id_catalog;
  const [idCategory] = id_category;
  const [isMobile] = is_mobile;
  const [current, setCurrent] = useState(1);
  const [productLength] = product_length;
  let loc_product = location.pathname.includes("product");
  let loc_penjualan = location.pathname.includes("penjualan");
  let loc_catalog = location.pathname.includes("catalog");
  let loc_category = location.pathname.includes("category");

  const onChangePagination = (pageNumber) => {
    setCurrent(pageNumber);
    if (loc_product || loc_penjualan) {
      getProduct("", 8, pageNumber);
    }
    if (loc_catalog) {
      getCatalogData(idCatalog, 10, pageNumber);
    }
    if (loc_category) {
      getCategoryData(idCategory, 10, pageNumber);
    }
  };

  const displayStar = (star) => {
    let fulfil = [];
    let empty = [];
    for (var i = 0; i < parseInt(star); i++) {
      fulfil.push(<ion-icon key={i} name="star"></ion-icon>);
    }
    for (var i = 0; i < 5 - parseInt(star); i++) {
      empty.push(<ion-icon key={i} name="star-outline"></ion-icon>);
    }
    return (
      <div className="showcase-rating">
        {fulfil}
        {empty}
      </div>
    );
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getSubCategory();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="product-main">
        {!props.isPOS && (
          <h2
            style={{
              marginTop: "10px",
              fontSize: `${isMobile ? "14px" : "16px"}`,
            }}
            className="title"
          >
            {props.title}
          </h2>
        )}
        {Object.keys(props.product).length > 0 ? (
          <div
            className={`${
              props.isCatalogCategory ? "product-grid small" : "product-grid"
            }`}
          >
            {props.product.length > 0 &&
              props.product.map((el, i) => (
                <div key={i}>
                  {props.isPOS ? (
                    <div className="showcase">
                      <Link to={`/product/${el.slug}`}>
                        <div className="showcase-banner">
                          <img
                            src={el.image_default}
                            alt=""
                            width="300"
                            className="product-img default"
                          />
                          {el.image_front ? (
                            <img
                              src={el.image_front}
                              alt=""
                              width="300"
                              className="product-img hover"
                            />
                          ) : (
                            <img
                              src={el.image_default}
                              alt=""
                              width="300"
                              className="product-img hover"
                            />
                          )}
                          {el.display_sale && (
                            <p className="showcase-badge angle black">sale</p>
                          )}
                          {el.display_new && (
                            <p className="showcase-badge angle pink">new</p>
                          )}
                          {/* <div className="showcase-actions">
                                    <button className="btn-action">
                                        <ion-icon name="heart-outline"></ion-icon>
                                    </button>
                                    <button className="btn-action">
                                        <ion-icon name="eye-outline"></ion-icon>
                                    </button>
                                    <button className="btn-action">
                                        <ion-icon name="repeat-outline"></ion-icon>
                                    </button>
                                    <button className="btn-action">
                                        <ion-icon name="bag-add-outline"></ion-icon>
                                    </button>
                                </div> */}
                        </div>
                      </Link>
                      <div
                        className="showcase-content"
                        style={{ paddingBottom: props.isPOS ? "10px" : "" }}
                      >
                        {subCategory.length > 0 &&
                          subCategory.map((item, idx) => {
                            if (item.id === el.subcategory) {
                              return (
                                <p className="showcase-category" key={idx}>
                                  {item.name}
                                </p>
                              );
                            }
                          })}
                        <h3 className="showcase-title">{el.name}</h3>
                        {!props.isPOS && <>{displayStar(el.star)}</>}
                        <div className="price-box">
                          {/* {el.have_old_price ? (
                                            <>
                                                <p className="price">{el.price}</p>
                                                <del>{el.price_old}</del>
                                            </>
                                        ) : ( */}
                          <p className="price">{convertToIDR(el.price)}</p>
                          {/* )} */}
                        </div>
                        <Button
                          type="primary"
                          className="btn-orange"
                          block
                          shape="round"
                          size={15}
                          onClick={() => props.postProductItem(el.id)}
                        >
                          + Keranjang
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Link to={`/product/${el.slug}`} key={i}>
                      <div className="showcase">
                        <div className="showcase-banner">
                          <img
                            src={el.image_default}
                            alt=""
                            width="300"
                            className="product-img default"
                          />
                          {el.image_front ? (
                            <img
                              src={el.image_front}
                              alt=""
                              width="300"
                              className="product-img hover"
                            />
                          ) : (
                            <img
                              src={el.image_default}
                              alt=""
                              width="300"
                              className="product-img hover"
                            />
                          )}
                          {el.display_sale && (
                            <p className="showcase-badge angle black">sale</p>
                          )}
                          {el.display_new && (
                            <p className="showcase-badge angle pink">new</p>
                          )}
                          {/* <div className="showcase-actions">
                                        <button className="btn-action">
                                            <ion-icon name="heart-outline"></ion-icon>
                                        </button>
                                        <button className="btn-action">
                                            <ion-icon name="eye-outline"></ion-icon>
                                        </button>
                                        <button className="btn-action">
                                            <ion-icon name="repeat-outline"></ion-icon>
                                        </button>
                                        <button className="btn-action">
                                            <ion-icon name="bag-add-outline"></ion-icon>
                                        </button>
                                    </div> */}
                        </div>
                        <div
                          className="showcase-content"
                          style={{ paddingBottom: props.isPOS ? "10px" : "" }}
                        >
                          {subCategory.length > 0 &&
                            subCategory.map((item, idx) => {
                              if (item.id === el.subcategory) {
                                return (
                                  <p className="showcase-category" key={idx}>
                                    {item.name}
                                  </p>
                                );
                              }
                            })}
                          <h3 className="showcase-title">{el.name}</h3>
                          {!props.isPOS && <>{displayStar(el.star)}</>}
                          <div className="price-box">
                            {/* {el.have_old_price ? (
                                                <>
                                                    <p className="price">{el.price}</p>
                                                    <del>{el.price_old}</del>
                                                </>
                                            ) : ( */}
                            <p className="price">{convertToIDR(el.price)}</p>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              ))}
          </div>
        ) : (
          <div
            className={`${
              props.isCatalogCategory ? "product-grid small" : "product-grid"
            }`}
            style={{
              height: "20rem",
              width: props.isPOS || isMobileView ? "100%" : "200%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        )}
      </div>
      {props.path && !props.path.includes("/search") && (
        <div
          style={{
            margin: "3px 10px 15px 10px",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Pagination
            total={productLength}
            showTotal={(total) => `Total ${total} Produk`}
            defaultCurrent={1}
            current={current}
            pageSize={10}
            onChange={onChangePagination}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default ProductGrid;

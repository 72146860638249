import {
  Button,
  Col,
  Input,
  message,
  Radio,
  Select,
  Table,
  Tooltip,
  Skeleton,
  Row,
  Card,
  Grid,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { MainContext } from "../context/MainContext";
import { convertToIDR } from "../Helper";
import Calculate from "./Calculate";
import { TrashIcon } from "./icons";
import ProductGrid from "./ProductGrid";

const host = process.env.REACT_APP_BACKEND;
const { useBreakpoint } = Grid;

const Checkout = (props) => {
  const {
    data_cart,
    product_list,
    getProduct,
    getProductLength,
    is_loading,
    data_history_os,
  } = useContext(MainContext);
  const history = useHistory();
  const [dataCart, setDataCart] = data_cart;
  const [product, setProduct] = product_list;
  const [isLoading, setIsLoading] = is_loading;
  const [dataHistoryOS, setDataHistoryOS] = data_history_os;
  const [dataOption, setDataOption] = useState([]);
  const [active, setActive] = useState("member");
  const [targetCustomer, setTargetCustomer] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [totalBonus, setTotalBonus] = useState(0);
  const [idCart, setIdCart] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [keyDebounced] = useDebounce(keySearch, 500);
  const [total, setTotal] = useState(0);
  const [loadings, setLoadings] = useState([]);
  const [loadSkeleton, setLoadSkeleton] = useState(false);
  const [showProductSelection, setShowProductSelection] = useState(false);
  const { lg } = useBreakpoint();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1000);
  };

  const handleDeleteItem = (id) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios.delete(`${host}/api/v1/cart_item/${id}/`, key).then((res) => {
      if (res.status === 200) {
        getCartItem();
        message.success("Berhasil menghapus barang", 1);
      }
    });
  };

  const calcByServer = (cartItemid, product_id, num) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios
      .patch(
        `${host}/api/v1/cart_item/${cartItemid}/`,
        {
          product_id: product_id,
          qty: num,
        },
        key
      )
      .then((res) => {
        if (res.status === 200) {
          getCartItem();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            props.history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getHistory = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/sales_order?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataHistoryOS(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Memuat Data Transaksi, Silahkan Coba Lagi"
          );
        }
      });
  };

  const postSO = () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios
      .post(
        `${host}/api/v1/sales_order/`,
        {
          cart_id: idCart,
        },
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setKeySearch("");
          setDataOption([]);
          message.success("Berhasil checkout pesanan!", 1);
          getCartItem();
          getHistory();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            props.history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const columnsDesktop = [
    {
      dataIndex: "product_img",
      title: "Gambar",
      render: (obj) => <img src={obj} height={100} />,
      width: "5%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Nama Produk",
      dataIndex: "product_name",
      render: (obj) => obj,
      width: "20%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Harga",
      dataIndex: "harga",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Harga Bonus",
      dataIndex: "harga_bonus",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (obj, el) => <Calculate el={el} calcByServer={calcByServer} />,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Subtotal Bonus",
      dataIndex: "subtotal_bonus",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Aksi",
      dataIndex: "",
      render: (obj, el) => (
        <Tooltip placement="bottomRight" title={"Hapus"}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteItem(el.cart_item_id)}
          >
            <TrashIcon />
          </div>
        </Tooltip>
      ),
      width: "5%",
      sorter: false,
      sortOrder: null,
    },
  ];

  const getMemberData = (keySearch) => {
    let tmp = [];
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios
      .get(`${host}/api/v1/member/${keySearch && "?search=" + keySearch}`, key)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data?.length > 0) {
            res.data.map((el) => tmp.push({ value: el.id, label: el.nama }));
            setDataOption(tmp);
          } else {
            setDataOption([]);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            props.history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const determineKeySearch = (search_key) => {
    setSearchTerm(search_key.toLowerCase());
  };

  const getProductSearch = async (slug, limit, page) => {
    if (slug !== "") {
      await axios
        .get(
          `${host}/api/v1/product/?search=${slug}&limit=${limit}&page=${page}`
        )
        .then((res) => {
          if (res.status === 200) {
            setProduct(res.data);
          }
        });
    }
  };

  useEffect(() => {
    if (searchTerm !== "") {
      getProductSearch(searchTerm, 8, 1);
    } else {
      axios.get(`${host}/api/v1/product/?limit=8&page=1`).then((res) => {
        if (res.status === 200) {
          setProduct(res.data.data);
        }
      });
    }
  }, [searchTerm]);

  const getNonMemberData = (keySearch) => {
    let tmp = [];
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios
      .get(
        `${host}/api/v1/member_search/${keySearch && "?search=" + keySearch}`,
        key
      )
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data?.length > 0) {
            res.data.map((el) => tmp.push({ value: el.id, label: el.nama }));
            setDataOption(tmp);
          } else {
            setDataOption([]);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            props.history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getCartItem = async () => {
    setLoadSkeleton(true);
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios.get(`${host}/api/v1/cart/`, key).then((res) => {
      if (res.status === 200) {
        setDataCart(res.data.data);
        setIsLoading(false);
        setLoadSkeleton(false);
      }
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    if (active && keyDebounced) {
      switch (active) {
        case "member":
          getMemberData(keyDebounced);
          break;
        case "non-member":
          getNonMemberData(keyDebounced);
          break;
        default:
          break;
      }
    }
    getProductLength();
    getProduct("", 8, 1);
    getCartItem();
  }, [active, keyDebounced]);

  useEffect(() => {
    setTotal(dataCart.grandtotal);
    setTotalBonus(dataCart.grandtotal_bonus);
    setIdCart(dataCart.cart_id);
  }, [dataCart]);

  const handleChange = (opt) => {
    setActive(opt);
    setTargetCustomer({});
  };

  const resetCart = () => {
    enterLoading(0);
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios.delete(`${host}/api/v1/cart/${dataCart.cart_id}`, key).then((res) => {
      if (res.status === 200) {
        setDataCart({});
        setTargetCustomer({});
      }
    });
  };

  const postProductItem = (item) => {
    let payload = {
      product_id: item,
      qty: 1,
      member_id: dataCart.member_id || targetCustomer.value,
    };
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios.post(`${host}/api/v1/cart/`, payload, key).then((res) => {
      if (res.status === 200) {
        getCartItem();
        message.success("Berhasil menambahkan produk", 1);
      }
    });
  };

  const handleShowCart = () => {
    setShowProductSelection(false);
  };

  const handleChangeSelection = (optionA, optionB) => {
    setTargetCustomer(optionB);
    setDataCart({
      ...dataCart,
      member_id: optionB.value,
      member_name: optionB.label,
    });
  };

  const handleDeleteCart = () => {
    resetCart();
  };

  return (
    <>
      {showProductSelection ? (
        <>
          <Col
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              height: "30px",
            }}
          >
            <Button type="primary" onClick={() => handleShowCart()}>
              Lihat Keranjang Saya
            </Button>
          </Col>
          <div className="header-main" style={{ marginBottom: "20px" }}>
            <div className="container" style={{ padding: 0 }}>
              <div className="header-search-container">
                <input
                  type="text"
                  name="search"
                  autocomplete="off"
                  className="search-field"
                  value={searchTerm}
                  placeholder={`Cari nama produk`}
                  onChange={(e) => determineKeySearch(e.target.value)}
                />
                <button className="search-btn" style={{ marginRight: "4px" }}>
                  <img src="/assets/images/icons/search.svg" height={17} />
                </button>
              </div>
            </div>
          </div>
          {product.length > 0 ? (
            <ProductGrid
              path={"/product"}
              isPOS
              product={product}
              postProductItem={postProductItem}
            />
          ) : (
            <div
              style={{
                height: lg ? "15rem" : "30rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/images/icons/empty.png"
                height={100}
                style={{ marginBottom: "20px" }}
              />
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                Produk Tidak Ditemukan
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {Object.keys(dataCart).length === 0 ? (
            <>
              <Radio.Group
                defaultValue={active}
                className="radio-cart"
                buttonStyle="solid"
                onChange={(e) => handleChange(e.target.value)}
                style={{ marginBottom: "20px" }}
              >
                <Radio.Button value="member">Member Saya</Radio.Button>
                <Radio.Button value="non-member">
                  Bukan Member Saya
                </Radio.Button>
              </Radio.Group>
              <Input
                onChange={(e) => setKeySearch(e.target.value)}
                placeholder="Cari nama member"
                value={keySearch}
                style={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              />
              {dataOption?.length > 0 && (
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  placeholder={`Hasil Pencarian untuk ${keySearch} (${dataOption?.length} Data)`}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={dataOption}
                  onChange={(optionA, optionB) =>
                    handleChangeSelection(optionA, optionB)
                  }
                  value={targetCustomer.label}
                />
              )}
            </>
          ) : (
            <>
              <div style={{ marginBottom: "20px" }}>
                <Input
                  size="small"
                  autocomplete="off"
                  value={dataCart.member_name}
                  disabled
                />
              </div>
            </>
          )}

          <Col
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              height: "50px",
            }}
          >
            {dataCart && dataCart?.member_id && (
              <Button
                style={{ marginRight: "15px" }}
                loading={loadings[0]}
                onClick={() => handleDeleteCart()}
              >
                {dataCart && dataCart?.products?.length
                  ? "Perbarui Keranjang"
                  : "Ganti Pembeli"}
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => setShowProductSelection(true)}
              disabled={!dataCart.member_id}
            >
              Tambah Produk
            </Button>
          </Col>
          {isMobile ? (
            <div style={{ width: "100%" }}>
              {dataCart?.products?.length > 0 ? (
                dataCart?.products?.map((el, i) => (
                  <Card
                    key={i}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        bottom: "10px",
                        border: "1px solid #ddd",
                        padding: "5px",
                        display: "flex",
                        borderRadius: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleDeleteItem(el.cart_item_id)}
                    >
                      <TrashIcon size={20} />
                    </div>
                    <Row>
                      <Col span={10}>
                        <img src={el.product_img} height={100} />
                      </Col>
                      <Col span={12}>
                        <p style={{ fontWeight: 600 }}>{el.product_name}</p>
                        <p>
                          Harga :{" "}
                          <span style={{ fontWeight: 600, color: "#457b9d" }}>
                            {convertToIDR(el.harga)}
                          </span>
                        </p>
                        <p>
                          Harga Bonus :{" "}
                          <span style={{ fontWeight: 600, color: "#457b9d" }}>
                            {convertToIDR(el.harga_bonus)}
                          </span>
                        </p>
                        <div style={{ marginBottom: "10px" }}>
                          <Calculate el={el} calcByServer={calcByServer} />
                        </div>
                        <p>
                          Subtotal :{" "}
                          <span style={{ fontWeight: 600, color: "#90323d" }}>
                            {convertToIDR(el.subtotal)}
                          </span>
                        </p>
                        <p>
                          Subtotal Bonus :{" "}
                          <span style={{ fontWeight: 600, color: "#90323d" }}>
                            {convertToIDR(el.subtotal_bonus)}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Card>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "27rem",
                  }}
                >
                  <img
                    src="/assets/images/icons/empty-cart.png"
                    height={100}
                    style={{ marginBottom: "5px" }}
                  />
                  <p style={{ textAlign: "center", fontWeight: "600" }}>
                    Keranjang Kosong
                  </p>
                </div>
              )}
            </div>
          ) : (
            <Table
              sorter={false}
              sortOrder="null"
              columns={columnsDesktop}
              rowKey="cart_item_id"
              dataSource={dataCart.products}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          )}
          {idCart && (
            <div className="col-lg-4 col-md-6">
              <div className="discount-code-wrapper">
                <h4
                  style={{
                    fontSize: isMobile ? "15px" : "17px",
                    fontWeight: 400,
                  }}
                >
                  Total Pesanan
                </h4>
                <div className="discount-code">
                  <Col
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? "14px" : "16px",
                        fontWeight: 600,
                      }}
                    >
                      Total Bonus
                    </div>
                    <div
                      style={{
                        fontSize: isMobile ? "14px" : "16px",
                        fontWeight: 600,
                      }}
                    >
                      {loadSkeleton ? (
                        <Skeleton.Input active size={20} />
                      ) : (
                        <>{convertToIDR(totalBonus)}</>
                      )}
                    </div>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? "14px" : "16px",
                        fontWeight: 600,
                      }}
                    >
                      Total
                    </div>
                    <div
                      style={{
                        fontSize: isMobile ? "14px" : "16px",
                        fontWeight: 600,
                      }}
                    >
                      {loadSkeleton ? (
                        <Skeleton.Input active size={20} />
                      ) : (
                        <>{convertToIDR(total)}</>
                      )}
                    </div>
                  </Col>
                  <Button
                    type="primary"
                    className="btn-orange"
                    block
                    shape="round"
                    size={15}
                    style={{
                      height: "50px",
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                    onClick={() => postSO()}
                  >
                    Submit Pesanan
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Checkout;

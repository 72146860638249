import { message, Spin } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import HistoryBonusMember from "../components/HistoryBonusMember";
import HistoryPOSMember from "../components/HistoryPOSMember";
import { HistoryBonus, HistoryCartInfo, OSInfoIcon } from "../components/icons";
import ModalBonus from "../components/ModalBonus";
import { MainContext } from "../context/MainContext";
import { capitalize } from "../Helper";
import "../Member.css";
import Layout from "./Layout";

const host = process.env.REACT_APP_BACKEND;

const Member = () => {
  const location = useLocation();
  const history = useHistory();
  const dataFetchedRef = useRef(false);
  const {
    is_loading,
    data_bonus_member,
    data_history_member,
    data_bonus_member_by_id,
  } = useContext(MainContext);
  const [isLoading, setIsLoading] = is_loading;
  const [dataMember, setDataMember] = useState({});
  const [searchHistory, setSearchHistory] = useState("");
  const [searchBonus, setSearchBonus] = useState("");
  const [dataBonusMember, setDataBonusMember] = data_bonus_member;
  const [dataBonusMemberById, setDataBonusMemberById] = data_bonus_member_by_id;
  const [dataHistoryMember, setDataHistoryMember] = data_history_member;
  const [slugBonus, setSlugBonus] = useState("");
  let loc = location.pathname.split("/");
  let memberCode = localStorage.getItem("MemberCode")
    ? JSON.parse(localStorage.getItem("MemberCode"))
    : [];
  let idMember = localStorage.getItem("IdMember")
    ? JSON.parse(localStorage.getItem("IdMember"))
    : [];

  const getMemberData = () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    axios
      .get(`${host}/api/v1/member/`, key)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setDataMember(res.data[0]);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          message.error("Token kadaluarsa, silahkan login kembali");
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getBonus = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/bonus?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataBonusMember(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getHistory = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/sales_order?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataHistoryMember(res.data.data);
        }
      });
  };

  useEffect(() => {
    onSearchHistory();
  }, [searchHistory]);

  useEffect(() => {
    onSearchBonus();
  }, [searchBonus]);

  useEffect(() => {
    if (typeof idMember === "number" && typeof memberCode === "string") {
      getBonusById(idMember, memberCode);
    }
  }, [memberCode, idMember]);

  const onSearchBonus = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchBonus !== "") {
      try {
        await axios
          .get(`${host}/api/v1/bonus/?search=${searchBonus}`, key)
          .then((res) => {
            if (res.status === 200) {
              setDataBonusMember(res.data.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Transaksi Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      getBonus(1, 10);
    }
  };

  const getBonusById = async (id, code) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(`${host}/api/v1/bonus/${id}/?member_id=${code}`, key)
      .then((res) => {
        if (res.status === 200) {
          setDataBonusMemberById(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const onSearchHistory = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchHistory !== "") {
      try {
        await axios
          .get(`${host}/api/v1/sales_order/?search=${searchHistory}`, key)
          .then((res) => {
            if (res.status === 200) {
              setDataHistoryMember(res.data.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Transaksi Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      getHistory(1, 10);
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getMemberData();
    getBonus(1, 10);
    getHistory(1, 10);
    setIsLoading(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [location]);

  const handleSearchHistory = (search_key) => {
    setSearchHistory(search_key.toLowerCase());
  };

  const handleSearchBonus = (search_key) => {
    setSearchBonus(search_key.toLowerCase());
  };

  const menuMember = [
    {
      title: "Informasi Akun Member",
      path: "/member/info",
      icon: <OSInfoIcon />,
      slug: "info",
      content: (
        <>
          <div className="panel-body">
            {Object.keys(dataMember).length > 0 ? (
              <div className="myaccount-info-wrapper">
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>ID Member</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.id_member !== null
                            ? dataMember.id_member
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Kota</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={dataMember.kota !== null ? dataMember.kota : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Official Store</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.os_name !== null ? dataMember.os_name : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Kodepos</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.kodepos !== null ? dataMember.kodepos : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Nama Lengkap</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={dataMember.nama !== null ? dataMember.nama : ""}
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Bank</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={dataMember.bank !== null ? dataMember.bank : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>No KTP</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.no_ktp !== null ? dataMember.no_ktp : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Cabang</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.cabang !== null ? dataMember.cabang : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Tanggal Lahir</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.tgl_lahir !== null
                            ? dataMember.tgl_lahir
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Nomor Rekening</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.no_rekening !== null
                            ? dataMember.no_rekening
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>No HP</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.no_hp !== null ? dataMember.no_hp : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Nama Sesuai Rekening</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.nama_sesuai_rekening !== null
                            ? dataMember.nama_sesuai_rekening
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Email</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.email !== null ? dataMember.email : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Referensi Dari</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.referensi_dari !== null
                            ? dataMember.referensi_dari
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>No NPWP</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.no_npwp !== null ? dataMember.no_npwp : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Nama Pemberi Referensi</label>
                      <input
                        disabled
                        type="text"
                        readOnly
                        style={{ backgroundColor: "#ECEFF8" }}
                        value={
                          dataMember.nama_pemberi_referensi !== null
                            ? dataMember.nama_pemberi_referensi
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-data">
                  <div className="form-input">
                    <div className="billing-info">
                      <label>Alamat</label>
                      <textarea
                        readOnly
                        rows="5"
                        value={
                          dataMember.alamat !== null ? dataMember.alamat : ""
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "20rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Riwayat Pembelian",
      path: "/member/riwayat-pembelian",
      icon: <HistoryCartInfo />,
      slug: "riwayat-pembelian",
      content: (
        <HistoryPOSMember
          searchHistory={searchHistory}
          handleSearchHistory={handleSearchHistory}
          getHistory={getHistory}
        />
      ),
    },
    {
      title: "Riwayat Bonus",
      path: "/member/riwayat-bonus",
      icon: <HistoryBonus />,
      slug: "riwayat-bonus",
      content: (
        <HistoryBonusMember
          searchBonus={searchBonus}
          handleSearchBonus={handleSearchBonus}
          getBonusById={getBonusById}
          getBonus={getBonus}
        />
      ),
    },
  ];

  const handleOnClick = (slug) => {
    history.push(`/member/${slug}`);
  };

  return (
    <Layout>
      <div
        className="main-content"
        style={{ minHeight: "70vh", height: "100%" }}
      >
        <div className="checkout-area pb-80 pt-20">
          <div className="container">
            <div
              className="ml-auto mr-auto col-lg-9"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {!loc.includes("detail") ? (
                <div
                  className="os_selection"
                  style={{ minWidth: "13rem", marginRight: "30px" }}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="dashboard-menu">
                        {menuMember.length > 0 &&
                          menuMember.map((el, i) => {
                            return (
                              <ul
                                className="nav"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                role="tablist"
                                key={i}
                              >
                                {el.slug === loc[loc.length - 1] ? (
                                  <li className="nav-item">
                                    <Link
                                      className={"nav-link active"}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        borderBottom: "1px solid #e2e9e1",
                                      }}
                                      to={`/member/${loc[loc.length - 1]}`}
                                    >
                                      {el.icon}
                                      <p style={{ marginLeft: "10px" }}>
                                        {capitalize(el.title)}
                                      </p>
                                    </Link>
                                  </li>
                                ) : (
                                  <li
                                    className="nav-item"
                                    onClick={() => handleOnClick(el.slug)}
                                  >
                                    <Link
                                      className={"nav-link"}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        borderBottom: "1px solid #e2e9e1",
                                      }}
                                      to={`/member/${loc[loc.length - 1]}`}
                                    >
                                      {el.icon}
                                      <p style={{ marginLeft: "10px" }}>
                                        {capitalize(el.title)}
                                      </p>
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div style={{ position: "relative", width: "100%" }}>
                {menuMember.length > 0 &&
                  menuMember.map((el, i) => (
                    <div key={i}>
                      {el.slug === loc[loc.length - 1] ? (
                        <>{el.content}</>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                {loc.includes("detail") ? (
                  <ModalBonus data={dataBonusMemberById} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(Member);

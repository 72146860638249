import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../context/MainContext";
import {
  LocationIcon,
  PhoneIcon,
  MailIcon,
  TwitterIcon,
  InstagramIcon,
  FacebookIcon,
} from "./icons";

const Footer = () => {
  const dataFetchedRef = useRef(false);
  const {
    hotline_data,
    getHotline,
    social_media,
    footer_list,
    getSocialMedia,
    getFooter,
  } = useContext(MainContext);
  const [hotline] = hotline_data;
  const [socialMedia] = social_media;
  const [footer] = footer_list;

  const displayIcon = (link) => {
    switch (link) {
      case "http://twitter.com":
        return <TwitterIcon />;
      case "http://instagram.com":
        return <InstagramIcon />;
      case "http://facebook.com":
      default:
        return <FacebookIcon />;
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getHotline();
    getSocialMedia();
    getFooter();
  }, []);

  return (
    <footer className="no-print">
      <div className="footer-nav">
        <div className="container">
          {footer.length > 0 &&
            footer.map((el, i) => {
              if (el.footer.length !== 0) {
                return (
                  <ul className="footer-nav-list" key={i}>
                    <li className="footer-nav-item">
                      <h2 className="nav-title">{el.name}</h2>
                    </li>
                    {el.footer.length > 0 &&
                      el.footer.map((item, idx) => (
                        <li className="footer-nav-item" key={idx}>
                          <a
                            target="_blank"
                            href={item.link}
                            className="footer-nav-link"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                );
              }
            })}
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Kontak</h2>
            </li>
            <li className="footer-nav-item flex">
              <div className="icon-box">
                <LocationIcon />
              </div>
              <a
                href={`${hotline.address_link}`}
                target="_blank"
                className="footer-nav-link"
              >
                {hotline.address}
              </a>
            </li>
            <li className="footer-nav-item flex">
              <div className="icon-box">
                <PhoneIcon />
              </div>
              <a
                href={`${hotline.phone_link}`}
                target="_blank"
                className="footer-nav-link"
              >
                {hotline.phone}
              </a>
            </li>
            <li className="footer-nav-item flex">
              <div className="icon-box">
                <MailIcon />
              </div>
              <a href={`mailto:${hotline.email}`} className="footer-nav-link">
                {hotline.email}
              </a>
            </li>
          </ul>
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Ikuti Kami</h2>
            </li>
            <li>
              <ul className="social-link">
                {socialMedia.length > 0 &&
                  socialMedia.map((el, i) => (
                    <li className="footer-nav-item" key={i}>
                      <a
                        href={el.link}
                        target="_blank"
                        className="footer-nav-link"
                      >
                        {displayIcon(el.link)}
                      </a>
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <p className="copyright">
            Copyright &copy; {new Date().getFullYear()}{" "}
            <Link to="/">Sheena Marvin</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { Grid, Input, message, Pagination, Spin } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CardMember from "../components/CardMember";
import Checkout from "../components/Checkout";
import HistoryBonusOfficialStore from "../components/HistoryBonusOfficialStore";
import HistoryPOSOfficialStore from "../components/HistoryPOSOfficialStore";
import {
  CartIcon,
  HistoryBonus,
  HistoryCartInfo,
  MyMemberIcon,
  OSInfoIcon,
} from "../components/icons";
import ModalBonus from "../components/ModalBonus";
import ModalMember from "../components/ModalMember";
import { MainContext } from "../context/MainContext";
import { capitalize } from "../Helper";
import "../Member.css";
import Layout from "./Layout";

const host = process.env.REACT_APP_BACKEND;
const { useBreakpoint } = Grid;

const MenuOfficialStore = () => {
  const location = useLocation();
  const history = useHistory();
  const { is_loading, data_history_os, data_bonus_os, data_bonus_os_by_id } =
    useContext(MainContext);
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = is_loading;
  const [dataOS, setDataOS] = useState({});
  const [memberOS, setMemberOS] = useState([]);
  const [singleDataMember, setSingleDataMember] = useState({});
  const [memberLength, setMemberLength] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [resultSearchMember, setResultSearchMember] = useState([]);
  const [searchMode, setSearchMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHistory, setSearchHistory] = useState("");
  const [searchBonus, setSearchBonus] = useState("");
  const [dataHistoryOS, setDataHistoryOS] = data_history_os;
  const [dataBonusOS, setDataBonusOS] = data_bonus_os;
  const [dataBonusOSById, setDataBonusOSById] = data_bonus_os_by_id;
  const { lg } = useBreakpoint();
  let loc = location.pathname.split("/");
  let memberCode = localStorage.getItem("MemberCode")
    ? JSON.parse(localStorage.getItem("MemberCode"))
    : [];
  let idMember = localStorage.getItem("IdMember")
    ? JSON.parse(localStorage.getItem("IdMember"))
    : [];

  const getBioOS = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    let username = token.username;
    setIsLoading(true);
    await axios
      .get(`${host}/api/v1/official_store/?search=${username}`, key)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setDataOS(res.data[0]);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          message.error("Token kadaluarsa, silahkan login kembali");
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getMemberOS = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    setIsLoading(true);
    await axios
      .get(`${host}/api/v1/member/?limit=${limit}&page=${page}`, key)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setMemberOS(res.data);
          }
          setIsLoading(false);
        }
      });
  };

  const getMemberLength = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios.get(`${host}/api/v1/member/`, key).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setMemberLength(res.data.length);
        }
      }
    });
  };

  const getMemberById = async (id) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios.get(`${host}/api/v1/member/${id}/`, key).then((res) => {
      if (res.status === 200) {
        setSingleDataMember(res.data);
      }
    });
  };

  const openModalMember = async (id) => {
    await getMemberById(id);
    setIsModalVisible(true);
  };

  const updateDataMember = async (newData) => {
    setIsLoading(true);
    const id = newData.id;
    const getOldData = async () => await getMemberById(id);
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    const payloadUpdate = {
      ...getOldData,
      id_member: newData.id_member,
      os_name: newData.os_name,
      nama: newData.nama,
      no_ktp: newData.no_ktp,
      tgl_lahir: newData.tgl_lahir,
      no_hp: newData.no_hp,
      email: newData.email,
      no_npwp: newData.no_npwp,
      alamat: newData.alamat,
      kota: newData.kota,
      kodepos: newData.kodepos,
      bank: newData.bank,
      cabang: newData.cabang,
      no_rekening: newData.no_rekening,
      nama_sesuai_rekening: newData.nama_sesuai_rekening,
      referensi_dari: newData.referensi_dari,
      nama_pemberi_referensi: newData.nama_pemberi_referensi,
    };
    try {
      await axios
        .put(`${host}/api/v1/member/${id}/`, payloadUpdate, key)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Berhasil Perbarui Data Member", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              const getData = async () => await getMemberOS(1, 8);
              getData();
            }, 100);
          } else {
            toast.error("Gagal Perbarui Data Member", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setIsLoading(false);
          setIsModalVisible(false);
        })
        .catch((err) => {
          let tmp = [];
          if (err.response.status === 400) {
            if (err.response.data.no_ktp) {
              tmp.push("No KTP");
            }
            if (err.response.data.no_hp) {
              tmp.push("No HP");
            }
            if (err.response.data.no_npwp) {
              tmp.push("No NPWP");
            }
            if (err.response.data.no_rekening) {
              tmp.push("No Rekening");
            }
            tmp.length > 0 &&
              tmp.map((el, i) => {
                toast.error(`${el} sudah terdaftar`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }
        });
      setIsLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        setTimeout(() => {
          history.push("/");
          localStorage.removeItem("isLoggedIn");
        }, 500);
      } else {
        message.error(
          "Terjadi Kesalahan Saat Memperbarui Data Member, Silahkan Coba Lagi"
        );
      }
    }
  };

  const handleOkModal = async (newData) => {
    await updateDataMember(newData);
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const onChangePagination = (pageNumber) => {
    setCurrent(pageNumber);
    getMemberOS(pageNumber, 8);
  };

  const onSearchMember = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchTerm !== "") {
      try {
        await axios
          .get(
            `${host}/api/v1/member/?${
              searchTerm !== "" && `search=${searchTerm}&`
            }limit=8&page=1`,
            key
          )
          .then((res) => {
            if (res.status === 200) {
              setResultSearchMember(res.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      setSearchMode(false);
    }
  };

  const getBonusById = async (id, code) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(`${host}/api/v1/bonus/${id}/?member_id=${code}`, key)
      .then((res) => {
        if (res.status === 200) {
          setDataBonusOSById(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const determineKeySearch = (search_key) => {
    setSearchTerm(search_key.toLowerCase());
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getBioOS();
    getMemberLength();
    getMemberOS(1, 8);
    getHistory(1, 10);
    getBonus(1, 10);
    setIsLoading(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    setSearchMode(true);
    onSearchMember();
  }, [searchTerm]);

  useEffect(() => {
    onSearchHistory();
  }, [searchHistory]);

  useEffect(() => {
    if (typeof idMember === "number" && typeof memberCode === "string") {
      getBonusById(idMember, memberCode);
    }
  }, [memberCode, idMember]);

  const handleOnClick = (slug) => {
    history.push(`/menuofficialstore/${slug}`);
  };

  const handleSearchHistory = (search_key) => {
    setSearchHistory(search_key.toLowerCase());
  };

  const handleSearchBonus = (search_key) => {
    setSearchBonus(search_key.toLowerCase());
  };

  const getHistory = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/sales_order?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataHistoryOS(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Memuat Data Transaksi, Silahkan Coba Lagi"
          );
        }
      });
  };

  const getBonus = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/bonus?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataBonusOS(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Memuat Data Bonus, Silahkan Coba Lagi"
          );
        }
      });
  };

  const menuOS = [
    {
      title: "Informasi Akun Official Store",
      path: "/menuofficialstore/info",
      icon: <OSInfoIcon />,
      slug: "info",
      content: (
        <>
          <div className="panel-body">
            {!isLoading ? (
              <>
                {Object.keys(dataOS).length > 0 ? (
                  <div className="myaccount-info-wrapper">
                    <div className="form-data">
                      <div className="form-input">
                        <div className="billing-info">
                          <label>Nama Official Store</label>
                          <input
                            disabled
                            type="text"
                            style={{ backgroundColor: "#ECEFF8" }}
                            value={dataOS.nama}
                          />
                        </div>
                      </div>
                      <div className="form-input">
                        <div className="billing-info">
                          <label>Link Youtube Video</label>
                          <input
                            disabled
                            type="text"
                            style={{ backgroundColor: "#ECEFF8" }}
                            value={dataOS.youtube}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-data">
                      <div className="form-input">
                        <div className="billing-info">
                          <label>Alamat</label>
                          <textarea
                            readOnly
                            rows="5"
                            value={dataOS.alamat}
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-input">
                        <div className="billing-info">
                          <label>Link Lokasi</label>
                          <textarea
                            readOnly
                            rows="5"
                            value={dataOS.link}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="form-data">
                      <div className="form-input">
                        <div className="billing-info">
                          <label>Gambar</label>
                          <img
                            src={`${dataOS.image}`}
                            className="img-os-profile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: lg ? "15rem" : "30rem",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/assets/images/icons/empty.png"
                      height={100}
                      style={{ marginBottom: "20px" }}
                    />
                    <p style={{ fontSize: "15px", fontWeight: "600" }}>
                      Informasi Akun Belum Tersedia
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div
                style={{
                  height: "20rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Daftar Member Saya",
      path: "/menuofficialstore/daftar-member",
      icon: <MyMemberIcon />,
      slug: "daftar-member",
      content: (
        <>
          <div className="header-main">
            <div className="container" style={{ padding: 0 }}>
              <div className="header-search-container">
                <Input
                  type="text"
                  name="search"
                  className="search-field"
                  value={searchTerm}
                  autocomplete="off"
                  placeholder={`Cari nama atau ID member`}
                  onChange={(e) => determineKeySearch(e.target.value)}
                />
                <button className="search-btn" style={{ marginRight: "4px" }}>
                  <img src="/assets/images/icons/search.svg" height={17} />
                </button>
              </div>
            </div>
          </div>
          <div className="product-main" style={{ padding: "20px 10px" }}>
            {searchMode ? (
              resultSearchMember.length !== 0 ? (
                <div className="product-grid-member">
                  {resultSearchMember.length > 0 &&
                    resultSearchMember.map((el, i) => (
                      <CardMember
                        key={i}
                        data={el}
                        openModalMember={openModalMember}
                      />
                    ))}
                </div>
              ) : (
                <div
                  style={{
                    height: "10rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Data Member Tidak Ditemukan
                </div>
              )
            ) : (
              <>
                {!isLoading ? (
                  <>
                    {Object.keys(memberOS).length > 0 ? (
                      <div className="product-grid-member">
                        {memberOS.length > 0 &&
                          memberOS.map((el, i) => (
                            <CardMember
                              key={i}
                              data={el}
                              openModalMember={openModalMember}
                            />
                          ))}
                      </div>
                    ) : (
                      <div
                        style={{
                          height: lg ? "15rem" : "30rem",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/images/icons/empty.png"
                          height={100}
                          style={{ marginBottom: "20px" }}
                        />
                        <p style={{ fontSize: "15px", fontWeight: "600" }}>
                          Anda Belum Memiliki Member
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="product-grid-member"
                    style={{
                      position: "relative",
                      height: "20rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spin />
                  </div>
                )}
              </>
            )}
          </div>
          {!searchMode && (
            <div style={{ float: "right", margin: "5px 10px" }}>
              <Pagination
                total={memberLength}
                showTotal={(total) => `Total ${total} Member`}
                defaultCurrent={1}
                current={current}
                pageSize={8}
                onChange={onChangePagination}
                showSizeChanger={false}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: "Menu Penjualan",
      path: "/menuofficialstore/penjualan",
      icon: <CartIcon />,
      slug: "penjualan",
      content: <Checkout history={history} />,
    },
    {
      title: "Riwayat Penjualan",
      path: "/menuofficialstore/riwayat-penjualan",
      icon: <HistoryCartInfo />,
      slug: "riwayat-penjualan",
      content: (
        <HistoryPOSOfficialStore
          searchHistory={searchHistory}
          handleSearchHistory={handleSearchHistory}
          getHistory={getHistory}
        />
      ),
    },
    {
      title: "Riwayat Bonus",
      path: "/menuadmin/riwayat-bonus",
      icon: <HistoryBonus />,
      slug: "riwayat-bonus",
      content: (
        <HistoryBonusOfficialStore
          searchBonus={searchBonus}
          handleSearchBonus={handleSearchBonus}
          getBonusById={getBonusById}
          getBonus={getBonus}
          dataBonusOSById={dataBonusOSById}
        />
      ),
    },
  ];

  useEffect(() => {
    onSearchBonus();
  }, [searchBonus]);

  const onSearchBonus = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchBonus !== "") {
      try {
        await axios
          .get(`${host}/api/v1/bonus/?search=${searchBonus}`, key)
          .then((res) => {
            if (res.status === 200) {
              setDataBonusOS(res.data.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Transaksi Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      getBonus(1, 10);
    }
  };

  const onSearchHistory = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchHistory !== "") {
      try {
        await axios
          .get(`${host}/api/v1/sales_order/?search=${searchHistory}`, key)
          .then((res) => {
            if (res.status === 200) {
              setDataHistoryOS(res.data.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Transaksi Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      getHistory(1, 10);
    }
  };

  return (
    <>
      <ToastContainer />
      {isModalVisible && (
        <ModalMember
          isModalVisible={isModalVisible}
          handleOkModal={handleOkModal}
          handleCancelModal={handleCancelModal}
          data={singleDataMember}
        />
      )}
      <Layout>
        <div
          className="main-content"
          style={{ minHeight: "70vh", height: "100%" }}
        >
          <div className="checkout-area pb-80 pt-20">
            <div className="container">
              <div
                className="ml-auto mr-auto col-lg-9"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {!loc.includes("detail") ? (
                  <div
                    className="os_selection"
                    style={{ minWidth: "13rem", marginRight: "30px" }}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="dashboard-menu">
                          {menuOS.length > 0 &&
                            menuOS.map((el, i) => {
                              return (
                                <ul
                                  className="nav"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  role="tablist"
                                  key={i}
                                >
                                  {el.slug === loc[loc.length - 1] ? (
                                    <li className="nav-item">
                                      <Link
                                        className={"nav-link active"}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          borderBottom: "1px solid #e2e9e1",
                                        }}
                                        to={`/menuofficialstore/${
                                          loc[loc.length - 1]
                                        }`}
                                      >
                                        {el.icon}
                                        <p style={{ marginLeft: "10px" }}>
                                          {capitalize(el.title)}
                                        </p>
                                      </Link>
                                    </li>
                                  ) : (
                                    <li
                                      className="nav-item"
                                      onClick={() => handleOnClick(el.slug)}
                                    >
                                      <Link
                                        className={"nav-link"}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          borderBottom: "1px solid #e2e9e1",
                                        }}
                                        to={`/menuofficialstore/${
                                          loc[loc.length - 1]
                                        }`}
                                      >
                                        {el.icon}
                                        <p style={{ marginLeft: "10px" }}>
                                          {capitalize(el.title)}
                                        </p>
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div style={{ position: "relative", width: "100%" }}>
                  {menuOS.length > 0 &&
                    menuOS.map((el, i) => (
                      <div key={i}>
                        {el.slug === loc[loc.length - 1] ? (
                          <>{el.content}</>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  {loc.includes("detail") ? (
                    <ModalBonus data={dataBonusOSById} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default React.memo(MenuOfficialStore);

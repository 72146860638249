import { Spin } from 'antd'
import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import ProductGrid from '../components/ProductGrid'
import { MainContext } from '../context/MainContext'
import { capitalize } from '../Helper'
import Layout from './Layout'

const Category = () => {
    const dataFetchedRef = useRef(false);
    const location = useLocation()
    const { is_loading, id_category, data_exist, getCategoryData, getCategoryBySlug, product_list } = useContext(MainContext)
    const [isLoading, setIsLoading] = is_loading
    const [idCategory] = id_category
    const [dataExist] = data_exist
    const [product] = product_list
    let loc = location.pathname.split("/")
    let title = loc[loc.length - 1].replace("-", " ")
    let slugCategory = loc[loc.length - 1].replace(/-/gi, "%20")

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setIsLoading(true)
        if (idCategory !== 0) {
            getCategoryData(idCategory, 10, 1)
        }
        getCategoryBySlug(slugCategory)
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }, [])

    return (
        <Layout>
            <div className="product-container">
                <div className="container">
                    {dataExist ? (
                        product.length > 0 ? (
                            <ProductGrid isCatalogCategory path={location.pathname} title={`Sub Kategori ${capitalize(title)}`} product={product} />
                        ) : (
                            <div style={{ height: "20rem", display: "flex", justifyContent: "center", alignItems: "center", width: '100%' }}>
                                <Spin />
                            </div>
                        )
                    ) : (
                        <div style={{ height: '30rem', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="/assets/images/icons/empty.png" height={100} style={{ marginBottom: "20px" }} />
                            <p style={{ fontSize: "15px", fontWeight: "600" }}>Data Sub Category Tidak Ditemukan</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default React.memo(Category)
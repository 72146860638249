import { useContext, useEffect } from 'react';
import './App.css';
import './style2.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import { MainContext } from './context/MainContext';
import Routes from './Routes';

function App() {
  const { handleScroll, handleResize } = useContext(MainContext)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [])

  return <Routes />
}

export default App;

import React, { useState } from "react";
import { MinusIcon, PlusIcon } from "./icons";

const Calculate = (props) => {
  const [amount, setAmount] = useState(props.el.qty);

  const updateQty = (cartItemid, product_id, type) => {
    let num = amount;
    if (type === "min") {
      if (num > 1) {
        num -= 1;
      }
    }
    if (type === "plus") {
      num += 1;
    }
    setAmount(num);
    props.calcByServer && props.calcByServer(cartItemid, product_id, num);
  };

  const updateQtyInput = (cartItemid, product_id, num) => {
    setAmount(parseInt(num));
    props.calcByServer &&
      props.calcByServer(cartItemid, product_id, parseInt(num));
  };

  return (
    <div className="input-group plus-minus-input">
      <div className="input-group-button">
        <button
          type="button"
          className="button hollow circle"
          data-quantity="minus"
          data-field="quantity"
          onClick={() => {
            updateQty(props.el.cart_item_id, props.el.product_id, "min");
          }}
        >
          <MinusIcon />
        </button>
      </div>
      <input
        className="input-group-field"
        type="number"
        autocomplete="off"
        name="quantity"
        onChange={(e) =>
          updateQtyInput(
            props.el.cart_item_id,
            props.el.product_id,
            e.target.value
          )
        }
        value={amount}
      />
      <div className="input-group-button">
        <button
          type="button"
          className="button hollow circle"
          data-quantity="plus"
          data-field="quantity"
          onClick={() => {
            updateQty(props.el.cart_item_id, props.el.product_id, "plus");
          }}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
};

export default Calculate;

import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Grid, message, Upload } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import HistoryBonusAdmin from "../components/HistoryBonusAdmin";
import HistoryPOSAdmin from "../components/HistoryPOSAdmin";
import { HistoryBonus, HistoryCartInfo, UploadIcon } from "../components/icons";
import ModalBonus from "../components/ModalBonus";
import { MainContext } from "../context/MainContext";
import { capitalize } from "../Helper";
import Layout from "./Layout";

const host = process.env.REACT_APP_BACKEND;
const { useBreakpoint } = Grid;

const MenuAdmin = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    is_loading,
    data_bonus_admin,
    data_history_admin,
    data_bonus_admin_by_id,
  } = useContext(MainContext);
  const [isLoading, setIsLoading] = is_loading;
  const [base64Member, setBase64Member] = useState("");
  const [base64Bonus, setBase64Bonus] = useState("");
  const [loadingUploadMember, setLoadingUploadMember] = useState();
  const [loadingUploadBonus, setLoadingUploadBonus] = useState();
  const [searchHistory, setSearchHistory] = useState("");
  const [searchBonus, setSearchBonus] = useState("");
  const [dataBonusAdmin, setDataBonusAdmin] = data_bonus_admin;
  const [dataBonusAdminById, setDataBonusAdminById] = data_bonus_admin_by_id;
  const [dataHistoryAdmin, setDataHistoryAdmin] = data_history_admin;
  const { lg } = useBreakpoint();
  let loc = location.pathname.split("/");
  let memberCode = localStorage.getItem("MemberCode")
    ? JSON.parse(localStorage.getItem("MemberCode"))
    : [];
  let idMember = localStorage.getItem("IdMember")
    ? JSON.parse(localStorage.getItem("IdMember"))
    : [];

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isCSV = file.type === "text/csv";
    if (!isCSV) {
      message.error("Upload gagal, tipe berkas bukan CSV!");
    }
    return isCSV || Upload.LIST_IGNORE;
  };

  const handleUploadMember = (info) => {
    if (info.file.status === "uploading") {
      setLoadingUploadMember(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoadingUploadMember(false);
        setBase64Member(url.split("base64,")[1]);
      });
    }
  };

  const handleUploadBonus = (info) => {
    if (info.file.status === "uploading") {
      setLoadingUploadBonus(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoadingUploadBonus(false);
        setBase64Bonus(url.split("base64,")[1]);
      });
    }
  };

  const uploadButtonMember = (
    <div>
      {loadingUploadMember ? (
        <LoadingOutlined />
      ) : (
        <InboxOutlined
          style={{ fontSize: "200%", color: "rgb(0, 135, 247)" }}
        />
      )}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Ketuk untuk mengupload file CSV (Max : 1)
      </div>
    </div>
  );

  const uploadButtonBonus = (
    <div>
      {loadingUploadBonus ? (
        <LoadingOutlined />
      ) : (
        <InboxOutlined
          style={{ fontSize: "200%", color: "rgb(0, 135, 247)" }}
        />
      )}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Ketuk untuk mengupload file CSV (Max : 1)
      </div>
    </div>
  );

  const uploadCSV = async (url, type) => {
    let data = "";
    switch (type) {
      case "member":
        data = base64Member;
        break;
      case "bonus":
        data = base64Bonus;
        break;
      default:
        break;
    }
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .post(
        url,
        {
          file_content: data,
        },
        key
      )
      .then((res) => {
        if (res?.data?.success) {
          message.success("Upload Berhasil!");
        } else {
          message.error("Upload Gagal!");
        }
        if (type === "member") {
          setBase64Member("");
        }
        if (type === "bonus") {
          setBase64Bonus("");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getBonusById = async (id, code) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(`${host}/api/v1/bonus/${id}/?member_id=${code}`, key)
      .then((res) => {
        if (res.status === 200) {
          setDataBonusAdminById(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getBonus(1, 10);
    getHistory(1, 10);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (typeof idMember === "number" && typeof memberCode === "string") {
      getBonusById(idMember, memberCode);
    }
  }, [memberCode, idMember]);

  const handleSearchHistory = (search_key) => {
    setSearchHistory(search_key.toLowerCase());
  };

  const handleSearchBonus = (search_key) => {
    setSearchBonus(search_key.toLowerCase());
  };

  const getHistory = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/sales_order?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataHistoryAdmin(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const getBonus = async (page, limit) => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    await axios
      .get(
        `${host}/api/v1/bonus?${limit && "limit=" + limit}${
          page && "&page=" + page
        }`,
        key
      )
      .then((res) => {
        if (res.status === 200) {
          setDataBonusAdmin(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        }
      });
  };

  const menuAdmin = [
    {
      title: "Upload Data Member",
      path: "/menuadmin/upload-member",
      icon: <UploadIcon />,
      slug: "upload-member",
      content: (
        <div>
          {lg ? (
            <div style={{ width: "100%", height: "3rem", padding: "0 1%" }}>
              <div className="info-upload">
                Contoh file CSV Data Member bisa di download
                <a
                  target="_blank"
                  href="https://static.cloudwolf.net/static/admin/format-member.csv"
                >
                  <p
                    style={{
                      marginLeft: "5px",
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#000",
                    }}
                  >
                    {" "}
                    disini
                  </p>
                </a>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%", height: "3rem", padding: "0 2%" }}>
              <div className="info-upload-mobile">
                Contoh file CSV Data Member bisa di download
                <a
                  target="_blank"
                  href="https://static.cloudwolf.net/static/admin/format-member.csv"
                >
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 700,
                      color: "#000",
                    }}
                  >
                    {" "}
                    disini
                  </p>
                </a>
              </div>
            </div>
          )}
          <div
            className={`dropzone ${!base64Member ? "empty" : ""}`}
            style={{ marginTop: !lg ? "40px" : "" }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="upload-list-inline"
              showUploadList={true}
              maxCount={1}
              action={`${host}/api/v1/upload_member/`}
              beforeUpload={beforeUpload}
              onChange={handleUploadMember}
              accept={".csv"}
              onRemove={() => setBase64Member("")}
            >
              {uploadButtonMember}
            </Upload>
          </div>
          <div className="row-center" style={{ padding: "0 1%" }}>
            {base64Member && (
              <Button
                type="primary"
                size={30}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  uploadCSV(`${host}/api/v1/upload_member/`, "member")
                }
                block
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Upload Data Bonus",
      path: "/menuadmin/upload-bonus",
      icon: <UploadIcon />,
      slug: "upload-bonus",
      content: (
        <div>
          {lg ? (
            <div style={{ width: "100%", height: "3rem", padding: "0 1%" }}>
              <div
                style={{
                  background: "#f9d6bd",
                  borderRadius: "8px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: lg ? "16px" : "15px",
                }}
              >
                Contoh file CSV Data Bonus bisa di download
                <a
                  target="_blank"
                  href="https://static.cloudwolf.net/static/admin/format-bonus.csv"
                >
                  <p
                    style={{
                      marginLeft: "5px",
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#000",
                    }}
                  >
                    {" "}
                    disini
                  </p>
                </a>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%", height: "3rem", padding: "0 2%" }}>
              <div className="info-upload-mobile">
                Contoh file CSV Data Bonus bisa di download
                <a
                  target="_blank"
                  href="https://static.cloudwolf.net/static/admin/format-bonus.csv"
                >
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 700,
                      color: "#000",
                    }}
                  >
                    {" "}
                    disini
                  </p>
                </a>
              </div>
            </div>
          )}
          <div
            className={`dropzone ${!base64Bonus ? "empty" : ""}`}
            style={{ marginTop: !lg ? "40px" : "" }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="upload-list-inline"
              showUploadList={true}
              maxCount={1}
              action={`${host}/api/v1/upload_bonus/`}
              beforeUpload={beforeUpload}
              onChange={handleUploadBonus}
              accept={".csv"}
              onRemove={() => setBase64Bonus("")}
            >
              {uploadButtonBonus}
            </Upload>
          </div>
          <div className="row-center" style={{ padding: "0 1%" }}>
            {base64Bonus && (
              <Button
                type="primary"
                size={30}
                onClick={() =>
                  uploadCSV(`${host}/api/v1/upload_bonus/`, "bonus")
                }
                block
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Riwayat Penjualan",
      path: "/menuadmin/riwayat-penjualan",
      icon: <HistoryCartInfo />,
      slug: "riwayat-penjualan",
      content: (
        <HistoryPOSAdmin
          searchHistory={searchHistory}
          handleSearchHistory={handleSearchHistory}
          getHistory={getHistory}
        />
      ),
    },
    {
      title: "Riwayat Bonus",
      path: "/menuadmin/riwayat-bonus",
      icon: <HistoryBonus />,
      slug: "riwayat-bonus",
      content: (
        <HistoryBonusAdmin
          searchBonus={searchBonus}
          handleSearchBonus={handleSearchBonus}
          getBonusById={getBonusById}
          getBonus={getBonus}
          dataBonusAdminById={dataBonusAdminById}
        />
      ),
    },
  ];

  const handleOnClick = (slug) => {
    history.push(`/menuadmin/${slug}`);
  };

  useEffect(() => {
    onSearchHistory();
  }, [searchHistory]);

  useEffect(() => {
    onSearchBonus();
  }, [searchBonus]);

  const onSearchBonus = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchBonus !== "") {
      try {
        await axios
          .get(`${host}/api/v1/bonus/?search=${searchBonus}`, key)
          .then((res) => {
            if (res.status === 200) {
              setDataBonusAdmin(res.data.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Transaksi Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      getBonus(1, 10);
    }
  };

  const onSearchHistory = async () => {
    let token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let key = { headers: { Authorization: `Bearer ${token.access}` } };
    if (searchHistory !== "") {
      try {
        await axios
          .get(`${host}/api/v1/sales_order/?search=${searchHistory}`, key)
          .then((res) => {
            if (res.status === 200) {
              setDataHistoryAdmin(res.data.data);
            }
          });
      } catch (err) {
        if (err.response.status === 401) {
          setTimeout(() => {
            history.push("/");
            localStorage.removeItem("isLoggedIn");
          }, 500);
        } else {
          message.error(
            "Terjadi Kesalahan Saat Pencarian Transaksi Member, Silahkan Coba Lagi"
          );
        }
      }
    } else {
      getHistory(1, 10);
    }
  };

  return (
    <Layout>
      <div
        className="main-content"
        style={{ minHeight: "70vh", height: "100%" }}
      >
        <div className="checkout-area pb-80 pt-20">
          <div className="container">
            <div
              className="ml-auto mr-auto col-lg-9"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {!loc.includes("detail") ? (
                <div
                  className="os_selection"
                  style={{ minWidth: "13rem", marginRight: "30px" }}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="dashboard-menu">
                        {menuAdmin.length > 0 &&
                          menuAdmin.map((el, i) => {
                            return (
                              <ul
                                className="nav"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                role="tablist"
                                key={i}
                              >
                                {el.slug === loc[loc.length - 1] ? (
                                  <li className="nav-item">
                                    <Link
                                      className={"nav-link active"}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        borderBottom: "1px solid #e2e9e1",
                                      }}
                                      to={`/menuadmin/${loc[loc.length - 1]}`}
                                    >
                                      {el.icon}
                                      <p style={{ marginLeft: "10px" }}>
                                        {capitalize(el.title)}
                                      </p>
                                    </Link>
                                  </li>
                                ) : (
                                  <li
                                    className="nav-item"
                                    onClick={() => handleOnClick(el.slug)}
                                  >
                                    <Link
                                      className={"nav-link"}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        borderBottom: "1px solid #e2e9e1",
                                      }}
                                      to={`/menuadmin/${loc[loc.length - 1]}`}
                                    >
                                      {el.icon}
                                      <p style={{ marginLeft: "10px" }}>
                                        {capitalize(el.title)}
                                      </p>
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div style={{ position: "relative", width: "100%" }}>
                {menuAdmin.length > 0 &&
                  menuAdmin.map((el, i) => (
                    <div key={i}>
                      {el.slug === loc[loc.length - 1] ? (
                        <>{el.content}</>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                {loc.includes("detail") ? (
                  <ModalBonus data={dataBonusAdminById} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MenuAdmin;

import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button, Modal, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { convertToIDR } from "../Helper";
import { PrintIcon } from "./icons";
import Invoice from "./Invoice";

const ModalHistory = (props) => {
  const { isVisible, onOk, onCancel } = props;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  const columnsDesktop = [
    {
      title: "Gambar",
      dataIndex: "product_image",
      render: (obj) => <img src={obj} height={100} />,
      width: "20%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Nama Produk",
      dataIndex: "product_name",
      render: (obj) => obj,
      width: "20%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Harga",
      dataIndex: "product_price",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Harga Bonus",
      dataIndex: "product_price_bonus",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (obj, el) => obj,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Subtotal",
      dataIndex: "total",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      title: "Subtotal Bonus",
      dataIndex: "total_bonus",
      render: (obj) => `${convertToIDR(obj)}`,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <Modal
      title={`Pembelian SO00${props.cartId}`}
      visible={isVisible}
      onCancel={onCancel}
      width={1000}
      centered
      footer={false}
    >
      <Table
        sorter={false}
        sortOrder="null"
        columns={columnsDesktop}
        rowKey="product_id"
        dataSource={props.data}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
      <PDFDownloadLink
        document={
          <Invoice
            data={props.data}
            tglBeli={props.tglBeli}
            grandTotal={props.grandTotal}
            grandTotalBonus={props.grandTotalBonus}
            nama={props.nama}
            namaOS={props.namaOS}
            status={props.status}
          />
        }
        fileName={`Invoice ${moment().format("D MMMM YYYY HH.mm").toString()}`}
      >
        {({ loading }) =>
          loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                style={{
                  height: "35px",
                  width: "90px",
                  fontSize: "15.5px",
                  display: "flex",
                  width: "110px",
                  alignItems: "center",
                }}
                type="primary"
                disabled
              >
                <PrintIcon />{" "}
                <span style={{ marginLeft: "10px" }}>loading...</span>
              </Button>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                style={{
                  height: "35px",
                  width: "90px",
                  fontSize: "15.5px",
                  display: "flex",
                  width: "110px",
                  alignItems: "center",
                }}
                type="primary"
              >
                <PrintIcon /> <span style={{ marginLeft: "10px" }}>Cetak</span>
              </Button>
            </div>
          )
        }
      </PDFDownloadLink>
    </Modal>
  );
};

export default ModalHistory;

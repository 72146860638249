import React, { useContext } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MobileNavigation from '../components/MobileNavigation'
import ModalAuth from '../components/ModalAuth'
import Navbar from '../components/Navbar'
import { MainContext } from '../context/MainContext'
import LoadPage from "./LoadPage"
import { isMobile } from 'react-device-detect';

const Layout = ({ children }) => {
    const { closeSidebar, is_open_sidebar, show_modal, is_loading, status_nav } = useContext(MainContext)
    const [isOpenSidebar] = is_open_sidebar
    const [showModal] = show_modal
    const [statusNav] = status_nav
    const [isLoading] = is_loading

    if (isLoading) {
        return <LoadPage />
    } else {
        return (
            <>
                {statusNav ? (
                    <>
                        <Navbar />
                        <Header />
                    </>
                ) : (
                    <Header />
                )}
                {children}
                {isMobile && (<MobileNavigation />)}
                <Footer />
                {showModal && (
                    <ModalAuth />
                )}
                <div className={`backdrop ${isOpenSidebar ? "visible" : "hide"}`} onClick={() => closeSidebar()}></div>
            </>
        )
    }
}

export default React.memo(Layout)
import axios from "axios";
import { createContext, useState } from "react";

export const MainContext = createContext();

const host = process.env.REACT_APP_BACKEND;

export const MainProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalMember, setShowModalMember] = useState(false);
  const [idProduct, setIdProduct] = useState(0);
  const [idCatalog, setIdCatalog] = useState(0);
  const [idCategory, setIdCategory] = useState(0);
  const [productLength, setProductLength] = useState(0);
  const [idKota, setIdKota] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [authMode, setAuthMode] = useState("signin");
  const [searchResult, setSearchResult] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [hotline, setHotline] = useState({});
  const [aboutCompany, setAboutCompany] = useState({});
  const [aboutTeamDesc, setAboutTeamDesc] = useState({});
  const [aboutTeam, setAboutTeam] = useState([]);
  const [officialStore, setOfficialStore] = useState([]);
  const [marketingPlan, setMarketingPlan] = useState([]);
  const [marketingDesc, setMarketingDesc] = useState({});
  const [slidesCatalog, setSlidesCatalog] = useState([]);
  const [catalogBtn, setCatalogBtn] = useState([]);
  const [downloadCatalog, setDownloadCatalog] = useState({});
  const [OS, setOS] = useState([]);
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [kota, setKota] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [showDropdownAccount, setShowDropdownAccount] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [statusNav, setStatusNav] = useState(false);
  const [statusScroll, setStatusScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showAccordionCategory, setShowAccordionCategory] = useState(false);
  const [showAccordionOS, setShowAccordionOS] = useState(false);
  const [showMiniAccordion, setShowMiniAccordion] = useState(false);
  const [dataExist, setDataExist] = useState(true);
  const [footer, setFooter] = useState({});
  const [footerItem, setFooterItem] = useState({});
  const [currentIdx, setCurrentIdx] = useState(0);
  const [dataCart, setDataCart] = useState({});
  const [dataBonusAdmin, setDataBonusAdmin] = useState([]);
  const [dataBonusMember, setDataBonusMember] = useState([]);
  const [dataBonusOS, setDataBonusOS] = useState([]);
  const [dataBonusAdminById, setDataBonusAdminById] = useState([]);
  const [dataBonusMemberById, setDataBonusMemberById] = useState([]);
  const [dataBonusOSById, setDataBonusOSById] = useState([]);
  const [dataHistoryAdmin, setDataHistoryAdmin] = useState([]);
  const [dataHistoryMember, setDataHistoryMember] = useState([]);
  const [dataHistoryOS, setDataHistoryOS] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  const [socialTeam] = useState([
    {
      name: "facebook",
      icon: "icon-facebook.svg",
    },
    {
      name: "twitter",
      icon: "icon-twitter.svg",
    },
    {
      name: "instagram",
      icon: "icon-instagram.svg",
    },
    {
      name: "pinterest",
      icon: "icon-pinterest.svg",
    },
  ]);

  const getCategoryData = async (id, limit, page) => {
    await axios
      .get(`${host}/api/v1/subcategory/${id}/?limit=${limit}&page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.length) {
            setDataExist(true);
            setProduct(res.data.data[0].product);
          }
        } else {
          setDataExist(false);
        }
      });
  };

  const getCategoryBySlug = async (slugCategory) => {
    let id = 0;
    await axios
      .get(`${host}/api/v1/subcategory/?search=${slugCategory}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setDataExist(true);
            id = res.data[0].id;
            setIdCategory(res.data[0].id);
          } else {
            setDataExist(false);
          }
        }
        getCategoryData(id, 10, 1);
        getProductLength(id, "subcategory");
      });
  };

  const getCatalogData = async (id, limit, page) => {
    await axios
      .get(`${host}/api/v1/catalog/${id}/?limit=${limit}&page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data[0].product.length > 0) {
            setDataExist(true);
            setProduct(res.data.data[0].product);
          } else {
            setDataExist(false);
          }
        } else {
          setDataExist(false);
        }
      });
  };

  const getCatalogBySlug = async (slugCatalog) => {
    let id = 0;
    await axios
      .get(`${host}/api/v1/catalog/?search=${slugCatalog}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setDataExist(true);
            id = res.data[0].id;
            setIdCatalog(res.data[0].id);
          } else {
            setDataExist(false);
          }
        }
        getCatalogData(id, 10, 1);
        getProductLength(id, "catalog");
      });
  };

  const handleSearch = (search_key) => {
    setSearchTerm(search_key);
  };

  const getHotline = async () => {
    if (!Object.keys(hotline).length) {
      await axios.get(`${host}/api/v1/hotline/`).then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setHotline(res.data[0]);
          }
        }
      });
    }
  };

  const getAboutCompany = async () => {
    if (!Object.keys(aboutCompany).length) {
      await axios.get(`${host}/api/v1/about_company/`).then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setAboutCompany(res.data[0]);
          }
        }
      });
    }
  };

  const getSocialMedia = async () => {
    if (!socialMedia.length) {
      await axios.get(`${host}/api/v1/social_media/`).then((res) => {
        if (res.status === 200) {
          setSocialMedia(res.data);
        }
      });
    }
  };

  const getCatalogBtn = async () => {
    if (!catalogBtn.length) {
      await axios.get(`${host}/api/v1/catalog/`).then((res) => {
        if (res.status === 200) {
          setCatalogBtn(res.data);
        }
      });
    }
  };

  const getCategory = async () => {
    if (!category.length) {
      await axios.get(`${host}/api/v1/category/`).then((res) => {
        if (res.status === 200) {
          setCategory(res.data);
        }
      });
    }
  };

  const getSubCategory = async () => {
    if (!subCategory.length) {
      await axios.get(`${host}/api/v1/subcategory/`).then((res) => {
        if (res.status === 200) {
          setSubCategory(res.data);
        }
      });
    }
  };

  const getDownloadCatalog = async () => {
    if (!Object.keys(downloadCatalog).length) {
      await axios.get(`${host}/api/v1/catalog_card/`).then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setDownloadCatalog(res.data[0]);
          }
        }
      });
    }
  };

  const getAboutTeamDesc = async () => {
    if (!Object.keys(aboutTeamDesc).length) {
      await axios.get(`${host}/api/v1/about_team/`).then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setAboutTeamDesc(res.data[0]);
          }
        }
      });
    }
  };

  const getAboutTeam = async () => {
    if (!aboutTeam.length) {
      await axios.get(`${host}/api/v1/about_team_child/`).then((res) => {
        if (res.status === 200) {
          setAboutTeam(res.data);
        }
      });
    }
  };

  const getMarketingDesc = async () => {
    if (!Object.keys(marketingDesc).length) {
      await axios.get(`${host}/api/v1/about_marketing/`).then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setMarketingDesc(res.data[0]);
          }
        }
      });
    }
  };

  const getMarketingPlan = async () => {
    if (!marketingPlan.length) {
      await axios.get(`${host}/api/v1/about_marketing_child/`).then((res) => {
        if (res.status === 200) {
          setMarketingPlan(res.data);
        }
      });
    }
  };

  const getProductLength = async (id, type) => {
    if (id) {
      if (type === "catalog") {
        await axios.get(`${host}/api/v1/catalog/${id}`).then((res) => {
          if (res.status === 200) {
            setProductLength(res.data.meta.totalRecords);
          }
        });
      }
      if (type === "subcategory") {
        await axios.get(`${host}/api/v1/subcategory/${id}`).then((res) => {
          if (res.status === 200) {
            setProductLength(res.data.meta.totalRecords);
          }
        });
      }
    } else {
      await axios.get(`${host}/api/v1/product/`).then((res) => {
        if (res.status === 200) {
          setProductLength(res.data.meta.totalRecords);
        }
      });
    }
  };

  const getProduct = async (slug, limit, page) => {
    if (slug !== "") {
      await axios
        .get(
          `${host}/api/v1/product/?search=${slug}&limit=${limit}&page=${page}`
        )
        .then((res) => {
          if (res.status === 200) {
            setProduct(res.data);
          }
        });
    } else {
      await axios
        .get(`${host}/api/v1/product/?limit=${limit}&page=${page}`)
        .then((res) => {
          if (res.status === 200) {
            setProduct(res.data.data);
          }
        });
    }
  };

  const getSlidesCatalog = async () => {
    if (!slidesCatalog.length) {
      await axios.get(`${host}/api/v1/catalog_slide/`).then((res) => {
        if (res.status === 200) {
          setSlidesCatalog(res.data);
        }
      });
    }
  };

  const getFooter = async () => {
    if (!Object.keys(footer).length) {
      await axios.get(`${host}/api/v1/footer/`).then((res) => {
        if (res.status === 200) {
          setFooter(res.data);
        }
      });
    }
  };

  const getFooterItem = async () => {
    if (!Object.keys(footerItem).length) {
      await axios.get(`${host}/api/v1/footer_item/`).then((res) => {
        if (res.status === 200) {
          setFooterItem(res.data);
        }
      });
    }
  };

  const getProvinsi = async () => {
    if (!provinsi.length) {
      await axios.get(`${host}/api/v1/provinsi/`).then((res) => {
        if (res.status === 200) {
          setProvinsi(res.data);
        }
      });
    }
  };

  const getKota = async () => {
    if (!kota.length) {
      await axios.get(`${host}/api/v1/kota/`).then((res) => {
        if (res.status === 200) {
          setKota(res.data);
        }
      });
    }
  };

  const openSidebar = () => {
    setIsOpenSidebar(true);
  };

  const closeSidebar = () => {
    setIsOpenSidebar(false);
    setShowAccordionCategory(false);
    setShowAccordionOS(false);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position >= 60) {
      setStatusNav(true);
      setStatusScroll(true);
      if (window.innerWidth < 412) {
        setIsMobile(true);
      }
    } else {
      setStatusNav(false);
      setStatusScroll(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 412) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <MainContext.Provider
      value={{
        show_modal: [showModal, setShowModal],
        search_term: [searchTerm, setSearchTerm],
        hotline_data: [hotline, setHotline],
        about_company: [aboutCompany, setAboutCompany],
        about_team: [aboutTeam, setAboutTeam],
        about_marketing: [marketingPlan, setMarketingPlan],
        is_open_sidebar: [isOpenSidebar, setIsOpenSidebar],
        status_nav: [statusNav, setStatusNav],
        status_scroll: [statusScroll, setStatusScroll],
        is_mobile: [isMobile, setIsMobile],
        show_accordion_category: [
          showAccordionCategory,
          setShowAccordionCategory,
        ],
        show_accordion_os: [showAccordionOS, setShowAccordionOS],
        show_mini_accordion: [showMiniAccordion, setShowMiniAccordion],
        official_store: [officialStore, setOfficialStore],
        social_media: [socialMedia, setSocialMedia],
        slides_catalog: [slidesCatalog, setSlidesCatalog],
        catalog_btn: [catalogBtn, setCatalogBtn],
        social_team: [socialTeam],
        footer_list: [footer, setFooter],
        os: [OS, setOS],
        product_list: [product, setProduct],
        is_loading: [isLoading, setIsLoading],
        search_result: [searchResult, setSearchResult],
        category_list: [category, setCategory],
        download_catalog: [downloadCatalog, setDownloadCatalog],
        team_desc: [aboutTeamDesc, setAboutTeamDesc],
        marketing_desc: [marketingDesc, setMarketingDesc],
        sub_category: [subCategory, setSubCategory],
        footer_item: [footerItem, setFooterItem],
        provinsi_list: [provinsi, setProvinsi],
        kota_list: [kota, setKota],
        id_catalog: [idCatalog, setIdCatalog],
        id_category: [idCategory, setIdCategory],
        id_product: [idProduct, setIdProduct],
        id_kota: [idKota, setIdKota],
        search_data: [searchData, setSearchData],
        auth_mode: [authMode, setAuthMode],
        dropdown_account: [showDropdownAccount, setShowDropdownAccount],
        show_modal_member: [showModalMember, setShowModalMember],
        product_length: [productLength, setProductLength],
        data_exist: [dataExist, setDataExist],
        current_idx: [currentIdx, setCurrentIdx],
        data_cart: [dataCart, setDataCart],
        data_bonus_admin: [dataBonusAdmin, setDataBonusAdmin],
        data_bonus_member: [dataBonusMember, setDataBonusMember],
        data_bonus_os: [dataBonusOS, setDataBonusOS],
        data_bonus_admin_by_id: [dataBonusAdminById, setDataBonusAdminById],
        data_bonus_member_by_id: [dataBonusMemberById, setDataBonusMemberById],
        data_bonus_os_by_id: [dataBonusOSById, setDataBonusOSById],
        data_history_admin: [dataHistoryAdmin, setDataHistoryAdmin],
        data_history_member: [dataHistoryMember, setDataHistoryMember],
        data_history_os: [dataHistoryOS, setDataHistoryOS],
        data_to_print: [dataToPrint, setDataToPrint],

        handleSearch: handleSearch,
        getHotline: getHotline,
        getAboutCompany: getAboutCompany,
        getAboutTeam: getAboutTeam,
        openSidebar: openSidebar,
        closeSidebar: closeSidebar,
        handleScroll: handleScroll,
        handleResize: handleResize,
        getSocialMedia: getSocialMedia,
        getMarketingPlan: getMarketingPlan,
        getSlidesCatalog: getSlidesCatalog,
        getCatalogBtn: getCatalogBtn,
        getFooter: getFooter,
        getProduct: getProduct,
        getCategory: getCategory,
        getDownloadCatalog: getDownloadCatalog,
        getAboutTeamDesc: getAboutTeamDesc,
        getMarketingDesc: getMarketingDesc,
        getSubCategory: getSubCategory,
        getFooterItem: getFooterItem,
        getProvinsi: getProvinsi,
        getKota: getKota,
        getProductLength: getProductLength,
        getCatalogData: getCatalogData,
        getCatalogBySlug: getCatalogBySlug,
        getCategoryData: getCategoryData,
        getCategoryBySlug: getCategoryBySlug,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

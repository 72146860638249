import { Carousel, Modal } from 'antd'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { MainContext } from '../context/MainContext'

const ModalPreview = (props) => {
    const { current_idx } = useContext(MainContext)
    const [currentIdx] = current_idx
    const [currentSlide, setCurrentSlide] = useState(0)
    let slider1 = useRef(null)
    const { data, openPreview, setOpenPreview } = props
    const [listData] = useState([
        data.image_default !== "" ? data.image_default : undefined,
        data.image_front !== "" ? data.image_front : undefined,
        data.image_back !== "" ? data.image_back : undefined,
        data.image_side !== "" ? data.image_side : undefined
    ])

    const onChangeSlide = (i) => {
        slider1.current?.goTo(i)
    }

    useEffect(() => {
        setCurrentSlide(currentIdx)
        onChangeSlide(currentIdx)
    }, [currentIdx])

    return (
        <Modal
            title={data.name}
            centered
            visible={openPreview}
            width={600}
            onCancel={() => setOpenPreview(false)}
            footer={null}
        >
            <Carousel
                initialSlide={currentSlide}
                draggable
                autoplaySpeed={5000}
                swipeToSlide
                ref={slider1}
                dots={false}
                afterChange={(c) => {
                    onChangeSlide && onChangeSlide(c)
                    setCurrentSlide(c)
                }}
            >
                {listData.map((el, i) => {
                    if (el !== undefined) {
                        return (
                            <div className="product-image-slider" style={{ width: "100%" }} key={i}>
                                <img src={el} alt={data.name} className="img_product_detail" />
                            </div>
                        )
                    }
                })}
            </Carousel>
            <div
                style={{ position: 'absolute', top: '50%', left: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={() => onChangeSlide(currentSlide-1)}
            >
                <img src="/assets/images/icons/chevron-back.svg" height={40} />
            </div>
            <div
                style={{ position: 'absolute', top: '50%', right: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={() => onChangeSlide(currentSlide+1)}
            >
                <img src="/assets/images/icons/chevron-forward-front.svg" height={40} />
            </div>
        </Modal>
    )
}

export default ModalPreview
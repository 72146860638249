import React, { useContext, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MainContext } from "../context/MainContext";
import { capitalize } from "../Helper";
import { MyMemberIcon } from "./icons";

const MobileNavigation = () => {
  const token = localStorage.getItem("isLoggedIn")
    ? JSON.parse(localStorage.getItem("isLoggedIn"))
    : [];
  const dataFetchedRef = useRef(false);
  const location = useLocation();
  const history = useHistory();
  const {
    closeSidebar,
    is_loading,
    is_open_sidebar,
    id_category,
    show_accordion_category,
    show_accordion_os,
    social_media,
    category_list,
    getCategory,
    getProvinsi,
    provinsi_list,
  } = useContext(MainContext);
  const [isOpenSidebar] = is_open_sidebar;
  const [socialMedia] = social_media;
  const [isLoading, setIsLoading] = is_loading;
  const [idCategory, setIdCategory] = id_category;
  const [showAccordionCategory, setShowAccordionCategory] =
    show_accordion_category;
  const [showAccordionOS, setShowAccordionOS] = show_accordion_os;
  const [category] = category_list;
  const [provinsi] = provinsi_list;

  const menuOS = [
    {
      name: "Daftar Member Saya",
      path: "/menuofficialstore/daftar-member",
    },
    {
      name: "Menu Penjualan",
      path: "/menuofficialstore/penjualan",
    },
    {
      name: "Riwayat Penjualan",
      path: "/menuofficialstore/riwayat-penjualan",
    },
    {
      name: "Riwayat Bonus",
      path: "/menuofficialstore/riwayat-bonus",
    },
  ];
  const menuMember = [
    {
      name: "Riwayat Pembelian",
      path: "/member/riwayat-pembelian",
    },
    {
      name: "Riwayat Bonus",
      path: "/member/riwayat-bonus",
    },
  ];
  const menuAdmin = [
    {
      name: "Upload Data Member",
      path: "/menuadmin/upload-member",
    },
    {
      name: "Upload Data Bonus",
      path: "/menuadmin/upload-bonus",
    },
    {
      name: "Riwayat Bonus",
      path: "/menuadmin/riwayat-bonus",
    },
  ];

  const navigateOS = () => {
    setShowAccordionOS(false);
    closeSidebar();
  };

  const navigateCategory = (slug, id) => {
    setShowAccordionCategory(false);
    closeSidebar();
    setIdCategory(id);
    history.push(`/category/${slug}`);
    window.location.reload();
  };

  const toggleCategory = (index) => {
    if (showAccordionCategory === index) {
      return setShowAccordionCategory(null);
    }
    setShowAccordionCategory(index);
  };

  const toggleOS = (index) => {
    if (showAccordionOS === index) {
      return setShowAccordionOS(null);
    }
    setShowAccordionOS(index);
  };

  const logout = () => {
    if (location.pathname === "/") {
      setIsLoading(true);
      setTimeout(() => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("MemberCode");
        localStorage.removeItem("IdMember");
        setIsLoading(false);
      }, 500);
    } else {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("MemberCode");
      localStorage.removeItem("IdMember");
      history.push("/");
    }
    closeSidebar();
  };

  const displayAccountSidebar = () => {
    const token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    if (token) {
      let username = token.username;
      if (username) {
        return (
          <div className="sidebar-account">
            <div className="bg-account"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 99,
              }}
            >
              <img src="/assets/images/icons/person-circle.svg" height={20} />
              <h2 className="menu-title" style={{ marginTop: "7px" }}>
                {username}
              </h2>
            </div>
            <div className="menu-close-btn" onClick={() => closeSidebar()}>
              <ion-icon name="close-outline"></ion-icon>
            </div>
          </div>
        );
      }
    }
  };

  const navigateMenu = (path) => {
    history.push(`${path}`);
    closeSidebar();
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getCategory();
    getProvinsi();
  }, []);

  return (
    <nav className={`mobile-navigation-menu ${isOpenSidebar ? "active" : ""}`}>
      {displayAccountSidebar()}
      <div className="menu-top">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src="/assets/images/icons/grid.svg" height={14} />
          <h2 className="menu-title">Kategori</h2>
        </div>
        {!localStorage.getItem("isLoggedIn") && (
          <div className="menu-close-btn" onClick={() => closeSidebar()}>
            <ion-icon name="close-outline"></ion-icon>
          </div>
        )}
      </div>
      <ul className="mobile-menu-category-list">
        {category.length > 0 &&
          category.map((el, i) => {
            if (el.subcategory.length !== 0) {
              return (
                <li className="menu-category" key={i}>
                  <div
                    className="accordion-menu"
                    onClick={() => toggleCategory(i)}
                  >
                    <p className="menu-title" style={{ marginBottom: "0px" }}>
                      {capitalize(el.name)}
                    </p>
                    <div>
                      {showAccordionCategory === i ? (
                        <ion-icon
                          name="remove-outline"
                          className="remove-icon"
                        ></ion-icon>
                      ) : (
                        <ion-icon
                          name="add-outline"
                          className="add-icon"
                        ></ion-icon>
                      )}
                    </div>
                  </div>
                  {el.subcategory.length > 0 &&
                    el.subcategory.map((item, idx) => {
                      let nama = item.name.toLowerCase();
                      let slug = nama.replace(/ /g, "-");
                      return (
                        <ul
                          className={`submenu-category-list ${
                            showAccordionCategory === i ? "active" : ""
                          }`}
                          key={idx}
                        >
                          <li
                            className="submenu-category"
                            onClick={() => navigateCategory(slug, item.id)}
                          >
                            <p className="submenu-title">
                              {capitalize(item.name)}
                            </p>
                          </li>
                        </ul>
                      );
                    })}
                </li>
              );
            }
          })}
      </ul>

      {!token.isAdmin && token.isOs && (
        <>
          <div className="menu-top-os">
            <MyMemberIcon fill="#EC7825" />
            <h2 className="menu-title">Menu Official Store</h2>
          </div>
          <ul className="mobile-menu-category-list">
            {menuOS &&
              menuOS.map((el, i) => (
                <li
                  className="menu-category"
                  key={i}
                  onClick={() => navigateMenu(el.path)}
                >
                  <p className="menu-title">{capitalize(el.name)}</p>
                </li>
              ))}
          </ul>
        </>
      )}

      {!token.isOs && token.isMember && (
        <>
          <div className="menu-top-os">
            <MyMemberIcon fill="#EC7825" />
            <h2 className="menu-title">Menu Member</h2>
          </div>
          <ul className="mobile-menu-category-list">
            {menuMember &&
              menuMember.map((el, i) => (
                <li
                  className="menu-category"
                  key={i}
                  onClick={() => navigateMenu(el.path)}
                >
                  <p className="menu-title">{capitalize(el.name)}</p>
                </li>
              ))}
          </ul>
        </>
      )}

      {token.isAdmin && (
        <>
          <div className="menu-top-os">
            <MyMemberIcon fill="#EC7825" />
            <h2 className="menu-title">Menu Admin</h2>
          </div>
          <ul className="mobile-menu-category-list">
            {menuAdmin &&
              menuAdmin.map((el, i) => (
                <li
                  className="menu-category"
                  key={i}
                  onClick={() => navigateMenu(el.path)}
                >
                  <p className="menu-title">{capitalize(el.name)}</p>
                </li>
              ))}
          </ul>
        </>
      )}

      <div className="menu-top-os">
        <img src="/assets/images/icons/storefront.svg" height={16} />
        <h2 className="menu-title">Official Store</h2>
      </div>
      <ul className="mobile-menu-category-list">
        {provinsi.length > 0 &&
          provinsi.map((el, i) => {
            if (el.kota.length !== 0) {
              return (
                <li className="menu-category" key={i}>
                  <div className="accordion-menu" onClick={() => toggleOS(i)}>
                    <p className="menu-title" style={{ marginBottom: "0px" }}>
                      {capitalize(el.nama)}
                    </p>
                    <div>
                      {showAccordionOS === i ? (
                        <ion-icon
                          name="remove-outline"
                          className="remove-icon"
                        ></ion-icon>
                      ) : (
                        <ion-icon
                          name="add-outline"
                          className="add-icon"
                        ></ion-icon>
                      )}
                    </div>
                  </div>
                  {el.kota.length > 0 &&
                    el.kota.map((item, idx) => {
                      let namaProv = el.nama.toLowerCase();
                      let namaKota = item.nama.toLowerCase();
                      let slugProv = namaProv.replace(/ /g, "-");
                      let slugTempKota = namaKota.replace(/ /g, "-");
                      let slugKota = slugTempKota.replace(".", "");
                      return (
                        <ul
                          className={`submenu-category-list ${
                            showAccordionOS === i ? "active" : ""
                          }`}
                          key={idx}
                        >
                          <li
                            className="submenu-category"
                            onClick={() => navigateOS()}
                          >
                            <Link
                              to={`/officialstore/${slugProv}/${slugKota}`}
                              className="submenu-title"
                            >
                              {capitalize(item.nama)}
                            </Link>
                          </li>
                        </ul>
                      );
                    })}
                </li>
              );
            }
          })}
      </ul>
      {localStorage.getItem("isLoggedIn") && (
        <div className="menu-logout" onClick={() => logout()}>
          <ion-icon
            name="exit"
            style={{ fontSize: "20px", color: "#EC7825" }}
          ></ion-icon>
          <h2 className="menu-title">Keluar</h2>
        </div>
      )}
      <div className="menu-bottom">
        <ul className="menu-social-container">
          {socialMedia.length > 0 &&
            socialMedia.map((el, idx) => (
              <li key={idx}>
                <a
                  className="social-link footer-nav-link"
                  href={el.link}
                  target="_blank"
                >
                  <ion-icon name={el.icon}></ion-icon>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </nav>
  );
};

export default MobileNavigation;

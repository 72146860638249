import { Button, Card, Row, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { MainContext } from "../context/MainContext";
import SearchComp from "./SearchComp";

const HistoryBonusAdmin = (props) => {
  const { data_bonus_admin } = useContext(MainContext);
  const [dataBonusAdmin] = data_bonus_admin;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    props.getBonus(pagination.current, pagination.pageSize);
  };

  const columnsDesktop = [
    {
      key: "Periode",
      title: "Periode",
      dataIndex: "Periode",
      render: (obj) => moment(obj).format("MMMM YYYY"),
      width: "7%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "Lvl",
      title: "Level",
      dataIndex: "Lvl",
      render: (obj) => obj,
      width: "5%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "MemberCode",
      title: "Kode Member",
      dataIndex: "MemberCode",
      render: (obj) => obj,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "MemberName",
      title: "Nama Member",
      dataIndex: "MemberName",
      render: (obj) => obj,
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "DateJoined",
      title: "Tanggal Join",
      dataIndex: "DateJoined",
      render: (obj) => moment(obj).format("D MMMM YYYY"),
      width: "15%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "EMail",
      title: "Email",
      dataIndex: "EMail",
      render: (obj) => (obj === "NULL" ? "-" : obj),
      width: "10%",
      sorter: false,
      sortOrder: null,
    },
    {
      key: "aksi",
      title: "Aksi",
      dataIndex: "bonus_item",
      render: (obj, el) => (
        <Tooltip placement="bottomRight" title={"Lihat detail"}>
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("MemberCode", JSON.stringify(el.MemberCode));
              localStorage.setItem("IdMember", JSON.stringify(el.id));
              window.open(`/menuadmin/riwayat-bonus/${el.MemberCode}/detail`);
            }}
          >
            Detail
          </Button>
        </Tooltip>
      ),
      width: "5%",
      sorter: false,
      sortOrder: null,
    },
  ];

  const displayData = (title, content, color) => {
    return (
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography.Text>{title} :</Typography.Text>
        <Typography.Text
          ellipsis
          style={{ maxWidth: "200px", fontWeight: 600, color: color }}
        >
          {content}
        </Typography.Text>
      </Row>
    );
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h2 className="title">Riwayat Bonus</h2>
      </div>
      <SearchComp
        searchTerm={props.searchBonus}
        determineKeySearch={props.handleSearchBonus}
      />
      {isMobile ? (
        <Row span={24}>
          {dataBonusAdmin?.length > 0 ? (
            dataBonusAdmin?.map((el, i) => (
              <Card
                key={i}
                style={{ width: "100%", color: "#EC7825" }}
                type="inner"
                title={`${el.MemberName}`}
                extra={
                  <p
                    onClick={() => {
                      localStorage.setItem(
                        "MemberCode",
                        JSON.stringify(el.MemberCode)
                      );
                      localStorage.setItem("IdMember", JSON.stringify(el.id));
                      window.open(
                        `/menuadmin/riwayat-bonus/${el.MemberCode}/detail`
                      );
                    }}
                    style={{ color: "#EC7825", fontWeight: 500 }}
                  >
                    Detail
                  </p>
                }
              >
                {displayData(
                  "Periode",
                  el.Periode && moment(el.Periode).format("MMMM YYYY"),
                  "#457b9d"
                )}
                {displayData("Level", el.Lvl, "#457b9d")}
                {displayData("Kode Member", el.MemberCode, "#90323d")}
                {displayData(
                  "Tanggal Join",
                  el.DateJoined && moment(el.DateJoined).format("D MMMM YYYY"),
                  "#457b9d"
                )}
                {displayData("Email", el.EMail)}
              </Card>
            ))
          ) : (
            <div
              style={{
                height: "30rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/images/icons/empty-history.png"
                height={100}
                style={{ marginBottom: "20px" }}
              />
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                Belum ada Riwayat Bonus
              </p>
            </div>
          )}
        </Row>
      ) : (
        <Table
          sorter={false}
          sortOrder="null"
          columns={columnsDesktop}
          rowKey="id"
          dataSource={dataBonusAdmin}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      )}
    </>
  );
};

export default HistoryBonusAdmin;

import { Card, Skeleton } from 'antd';

const LoadOfficialStore = (props) => {
    return (
        <div className="os_wrap">
            <Card
                loading={props.loadData}
                cover={
                    <div style={{ width: "100%", backgroundColor: "#ECEFF8", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}>
                    </div>
                }
            >
                <Skeleton avatar paragraph={{ rows: 2 }} active width/>
            </Card>
            <Card
                loading={props.loadData}
                cover={
                    <div style={{ width: "100%", backgroundColor: "#ECEFF8", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}>
                    </div>
                }
            >
                <Skeleton avatar paragraph={{ rows: 2 }} active />
            </Card>
            <Card
                loading={props.loadData}
                cover={
                    <div style={{ width: "100%", backgroundColor: "#ECEFF8", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}>
                    </div>
                }
            >
                <Skeleton avatar paragraph={{ rows: 2 }} active />
            </Card>
        </div>
    )
}

export default LoadOfficialStore
import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import Banner from '../components/Banner'
import Download_Catalog from '../components/Download_Catalog'
import Product from '../components/Product'
import { MainContext } from '../context/MainContext'
import Layout from './Layout'

const Home = () => {
    const dataFetchedRef = useRef(false);
    const location = useLocation()
    const { product_list, is_loading, getProductLength, getProduct } = useContext(MainContext)
    const [product] = product_list
    const [isLoading, setIsLoading] = is_loading

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setIsLoading(true)
        getProductLength()
        getProduct('', 8, 1)
        window.scrollTo(0, 0)
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }, [location])

    return (
        <Layout>
            <main>
                <Banner />
                <Download_Catalog />
                <Product product={product} />
            </main>
        </Layout>
    )
}

export default React.memo(Home)
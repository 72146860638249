import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import About from "./pages/About";
import Catalog from "./pages/Catalog";
import Category from "./pages/Category";
import DetailProduct from "./pages/DetailProduct";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Member from "./pages/Member";
import MenuAdmin from "./pages/MenuAdmin";
import MenuOfficialStore from "./pages/MenuOfficialStore";
import OfficialStore from "./pages/OfficialStore";
import SearchPage from "./pages/SearchPage";

const Routes = () => {
  const token = localStorage.getItem("isLoggedIn")
    ? JSON.parse(localStorage.getItem("isLoggedIn"))
    : [];
  return (
    <Router>
      <Switch>
        <Route path="/" render={(props) => <About {...props} />} exact />
        <Route
          path="/officialstore"
          render={(props) => <OfficialStore {...props} />}
          exact
        />
        <Route
          path="/officialstore/:province"
          render={(props) => <OfficialStore {...props} />}
          exact
        />
        <Route
          path="/officialstore/:province/:city"
          render={(props) => <OfficialStore {...props} />}
          exact
        />
        <Route
          path="/member"
          render={(props) =>
            !token.isOs && token.isMember ? (
              <Member {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route
          path="/member/:menu"
          render={(props) =>
            !token.isOs && token.isMember ? (
              <Member {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route
          path="/member/:menu/:membercode/detail"
          render={(props) =>
            !token.isOs && token.isMember ? (
              <Member {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route
          path="/notfound"
          render={(props) => <ErrorPage {...props} />}
          exact
        />
        <Route path="/product" render={(props) => <Home {...props} />} exact />
        <Route
          path="/product/:product"
          render={(props) => <DetailProduct {...props} />}
          exact
        />
        <Route
          path="/catalog/:catalog_name"
          render={(props) => <Catalog {...props} />}
          exact
        />
        <Route
          path="/category/:subcategory"
          render={(props) => <Category {...props} />}
          exact
        />
        <Route
          path="/search"
          render={(props) => <SearchPage {...props} />}
          exact
        />
        <Route
          path="/menuofficialstore"
          render={(props) =>
            !token.isAdmin && token.isOs ? (
              <MenuOfficialStore {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route
          path="/menuofficialstore/:menu"
          render={(props) =>
            !token.isAdmin && token.isOs ? (
              <MenuOfficialStore {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route
          path="/menuofficialstore/:menu/:membercode/detail"
          render={(props) =>
            !token.isAdmin && token.isOs ? (
              <MenuOfficialStore {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
          exact
        />
        <Route
          path="/menuadmin"
          render={(props) =>
            token.isAdmin ? <MenuAdmin {...props} /> : <Redirect to="/" />
          }
          exact
        />
        <Route
          path="/menuadmin/:menu"
          render={(props) =>
            token.isAdmin ? <MenuAdmin {...props} /> : <Redirect to="/" />
          }
          exact
        />
        <Route
          path="/menuadmin/:menu/:membercode/detail"
          render={(props) =>
            token.isAdmin ? <MenuAdmin {...props} /> : <Redirect to="/" />
          }
          exact
        />
        <Route path="*" render={(props) => <ErrorPage {...props} />} exact />
      </Switch>
    </Router>
  );
};

export default Routes;

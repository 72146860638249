import React from 'react'
import ProductGrid from './ProductGrid'
import Sidebar from './Sidebar'

const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
const d = new Date()
const Product = (props) => {
    return (
        <>
            <div className="product-container">
                <div className="container">
                    <Sidebar />
                    <ProductGrid path={'/product'} isHome title={`Produk ${(months[d.getMonth()])} ${(new Date().getFullYear())}`} product={props.product} />
                </div>
            </div>
        </>
    )
}

export default Product
import React, { useContext, useEffect, useRef } from "react";
import { MainContext } from "../context/MainContext";
import parse from "html-react-parser";
import Layout from "./Layout";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

const About = () => {
  const dataFetchedRef = useRef(false);
  const location = useLocation();
  const {
    about_company,
    about_team,
    about_marketing,
    getAboutCompany,
    getAboutTeam,
    getMarketingPlan,
    getAboutTeamDesc,
    getMarketingDesc,
    is_loading,
    team_desc,
    marketing_desc,
    is_mobile,
  } = useContext(MainContext);
  const [aboutCompany] = about_company;
  const [aboutTeam] = about_team;
  const [marketingPlan] = about_marketing;
  const [isLoading, setIsLoading] = is_loading;
  const [aboutTeamDesc] = team_desc;
  const [marketingDesc] = marketing_desc;
  const [isMobile] = is_mobile;

  const { xl } = useBreakpoint();

  const settingsDesktop = {
    dots: true,
    autoplay: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 2,
  };
  const settingsMobile = {
    dots: false,
    autoplay: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const nothingTodo = (e) => {
    e.preventDefault();
  };

  const displayMarketingChild = (el) => {
    if (!isMobile) {
      if (el.pdf === "") {
        return (
          <a onClick={(e) => nothingTodo(e)}>
            <img src={el.image} alt="" style={{ height: "250px" }} />
          </a>
        );
      } else {
        return (
          <a href={el.pdf} target="_blank">
            <img src={el.image} alt="" style={{ height: "250px" }} />
          </a>
        );
      }
    } else {
      if (el.pdf === "") {
        return (
          <a onClick={(e) => nothingTodo(e)}>
            <img
              src={el.image}
              alt=""
              style={{ height: "auto", width: "300px" }}
            />
          </a>
        );
      } else {
        return (
          <a href={el.pdf} target="_blank">
            <img
              src={el.image}
              alt=""
              style={{ height: "auto", width: "300px" }}
            />
          </a>
        );
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    getAboutCompany();
    getAboutTeamDesc();
    getMarketingDesc();
    getAboutTeam();
    getMarketingPlan();
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [location]);

  return (
    <Layout>
      <div className="welcome-area pt-50 pb-30">
        <div className="container">
          <div className="welcome-content text-center">
            <h5>{aboutCompany && aboutCompany.section_label}</h5>
            <h1>{aboutCompany && aboutCompany.title}</h1>
            <p>{aboutCompany && aboutCompany.description}</p>
          </div>
        </div>
      </div>
      <div className="banner-area pb-70">
        <div className="container">
          <div className="about_event">
            <div className="col-lg-4 col-md-4">
              <div className="single-banner mb-30">
                <img
                  src={aboutCompany && aboutCompany.image1}
                  style={{ height: "300px", width: xl ? "auto" : "300px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-banner mb-30">
                <img
                  src={aboutCompany && aboutCompany.image2}
                  style={{ height: "300px", width: xl ? "auto" : "300px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-banner mb-30">
                <img
                  src={aboutCompany && aboutCompany.image3}
                  style={{ height: "300px", width: xl ? "auto" : "300px" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-mission-area">
        <div className="container">
          <div className="visi_misi_wrap">
            <div className="col-lg-4 col-md-4">
              <div className="single-mission mb-30">
                <h3>Visi Kami</h3>
                <p>{aboutCompany && aboutCompany.vision}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-mission mb-30">
                <h3>Misi Kami</h3>
                <p>{aboutCompany && parse(`${aboutCompany.mission}`)}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-mission mb-30">
                <h3>Tujuan Kami</h3>
                <p>{aboutCompany && aboutCompany.goal}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-area pt-50 pb-50 border_section">
        <div className="container">
          <div className="section-title-2 text-center mb-60">
            <h2>{aboutTeamDesc && aboutTeamDesc.section_label}</h2>
            <p>{aboutTeamDesc && aboutTeamDesc.description}</p>
          </div>
          <div>
            {!isMobile ? (
              <Slider {...settingsDesktop}>
                {aboutTeam.length > 0 &&
                  aboutTeam.map((el, i) => (
                    <div className="col-lg-3 col-md-6 col-sm-6" key={i}>
                      <div className="team-wrapper mb-30">
                        <div className="team-img">
                          <img
                            src={el.image}
                            alt=""
                            style={{ height: "200px", width: "100%" }}
                          />
                          {/* <div className="team-action">
                            <a className="facebook" href="#">
                              <ion-icon name="logo-facebook"></ion-icon>
                            </a>
                            <a className="twitter" title="Wishlist" href="#">
                              <ion-icon name="logo-twitter"></ion-icon>
                            </a>
                            <a className="instagram" href="#">
                              <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                          </div> */}
                        </div>
                        <div className="team-content text-center">
                          <h4>{el.name}</h4>
                          <span>{el.occupation}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            ) : (
              <div style={{ maxWidth: "97%" }}>
                <Slider {...settingsMobile}>
                  {aboutTeam.length > 0 &&
                    aboutTeam.map((el, i) => (
                      <div className="col-lg-3 col-md-6 col-sm-6" key={i}>
                        <div className="team-wrapper mb-30">
                          <div className="team-img">
                            <img
                              src={el.image}
                              alt=""
                              style={{ height: "200px", width: "100%" }}
                            />
                            {/* <div className="team-action">
                              <a className="facebook" href="#">
                                <ion-icon name="logo-facebook"></ion-icon>
                              </a>
                              <a className="twitter" title="Wishlist" href="#">
                                <ion-icon name="logo-twitter"></ion-icon>
                              </a>
                              <a className="instagram" href="#">
                                <ion-icon name="logo-instagram"></ion-icon>
                              </a>
                            </div> */}
                          </div>
                          <div className="team-content text-center">
                            <h4>{el.name}</h4>
                            <span>{el.occupation}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
      {marketingPlan.length ? (
        <>
          <div className="welcome-area pt-50 pb-30 border_section">
            <div className="container">
              <div className="section-title-2 text-center">
                <h2>{marketingDesc && marketingDesc.section_label}</h2>
              </div>
            </div>
          </div>
          <div className="banner-area pb-70">
            <div className="container">
              <div className="marketing_promo_wrap">
                {marketingPlan.length > 0 &&
                  marketingPlan.map((el, i) => (
                    <div className="col-lg-4 col-md-4" key={i}>
                      <p>{el.description}</p>
                      <div className="single-banner mb-30">
                        {displayMarketingChild(el)}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(About);

import React, { useContext, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { MainContext } from '../context/MainContext'

const Download_Catalog = () => {
    const dataFetchedRef = useRef(false);
    const history = useHistory()
    const location = useLocation()
    const { catalog_btn, download_catalog, getCatalogBtn, getDownloadCatalog, id_catalog, is_mobile } = useContext(MainContext)
    const [catalogBtn] = catalog_btn
    const [downloadCatalog] = download_catalog
    const [idCatalog, setIdCatalog] = id_catalog
    const [isMobile] = is_mobile

    const settingsMobile = {
        dots: false,
        autoplay: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const navigateTo = (slug, id) => {
        setIdCatalog(id)
        history.push(`/catalog/${slug}`)
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getDownloadCatalog()
        getCatalogBtn()
    }, [location])

    return (
        <div className="category">
            <div className="container">
                {/* <div className="suppoer-area pt-20">
                    <div className="container">
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", gap: "20px" }}>
                            {catalogBtn.length > 0 && catalogBtn.map((el, i) => {
                                let nama = (el.nama).toLowerCase()
                                let slug = nama.replace(" ", "-")
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => navigateTo(slug, el.id)} key={i}>
                                        <div className="support-wrap mb-30 support-1">
                                            <div className="support-icon">
                                                <img className="animated" src="/assets/images/catalog.png" alt="" />
                                            </div>
                                            <div className="support-content">
                                                <h5>{el.nama}</h5>
                                                <p>Lihat Disini</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {downloadCatalog.length > 0 && (
                                <a className="col-lg-3 col-md-6 col-sm-6" href={downloadCatalog[0].file_url} target="_blank">
                                    <div className="support-wrap mb-30 support-1">
                                        <div className="support-icon">
                                            <img className="animated" src="/assets/images/catalog.png" alt="" />
                                        </div>
                                        <div className="support-content">
                                            <h5>Download Catalog</h5>
                                            <p>Download</p>
                                        </div>
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>
                </div> */}
                <h2 className="title">Katalog Kami</h2>
                <div className="category-item-container">
                    {catalogBtn.length > 0 && catalogBtn.map((el, i) => {
                        let nama = (el.nama).toLowerCase()
                        let slug = nama.replace(" ", "-")
                        return (
                            <div className="category-item hover-up" key={i} style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "6rem" }} onClick={() => navigateTo(slug, el.id)}>
                                <img src="/assets/images/icons/bookmarks.svg" height={35} style={{ margin: "0 10px" }} />
                                <div className="category-content-box" style={{ cursor: "pointer" }}>
                                    <div className="category-content-flex">
                                        <h3 className="category-item-title">{el.nama}</h3>
                                    </div>
                                    <p className="category-btn">Lihat Disini</p>
                                </div>
                            </div>
                        )
                    })}
                    <a href={downloadCatalog.file_url} target="_blank">
                        <div className="category-item hover-up" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "6rem", width: "320px" }}>
                            <img src="/assets/images/icons/bookmarks.svg" height={35} style={{ margin: "0 10px" }} />
                            <div className="category-content-box" style={{ cursor: "pointer" }}>
                                <div className="category-content-flex">
                                    <h3 className="category-item-title">Download Catalog</h3>
                                </div>
                                <p className="category-btn">Download</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Download_Catalog
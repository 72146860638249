import { Input } from "antd";
import React from "react";

const SearchComp = (props) => {
  return (
    <div style={{ padding: "0 0 20px 0" }}>
      <div className="container" style={{ padding: 0 }}>
        <div className="header-search-container">
          <Input
            type="text"
            name="search"
            className="search-field"
            value={props.searchTerm}
            autocomplete="off"
            placeholder={`Cari nama member`}
            onChange={(e) => props.determineKeySearch(e.target.value)}
          />
          <button className="search-btn" style={{ marginRight: "4px" }}>
            <img src="/assets/images/icons/search.svg" height={17} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchComp;

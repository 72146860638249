import React from 'react'

const Review = () => {
    return (
        <div className="tab-pane" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "15px" }}>
            Ulasan Belum Tersedia
        </div>
    )
}

export default Review
import React, { useContext, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Slider from "react-slick";
import { MainContext } from '../context/MainContext';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
};

const Banner = () => {
    const dataFetchedRef = useRef(false);
    const slider = useRef();
    const { slides_catalog, getSlidesCatalog } = useContext(MainContext)
    const [slidesCatalog] = slides_catalog

    const handlePrevClick = () => {
        slider.current.slickPrev();
    }

    const handleNextClick = () => {
        slider.current.slickNext();
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getSlidesCatalog()
    }, [])

    return (
        <div className="banner">
            <div className="container">
                <div className="slider-item">
                    {!isMobile && (
                        <div className="button-slide" style={{ left: "10px" }} onClick={() => handlePrevClick()}>
                            <img src="/assets/images/icons/chevron-back.svg" height={20} />
                        </div>
                    )}
                    <Slider ref={slider} {...settings}>
                        {slidesCatalog.map((el, i) => (
                            <div className="slider-item" key={i}>
                                <img src={el.image_url} alt={el.image_url} className={`${isMobile ? 'banner-img-mobile' : 'banner-img'}`} />
                            </div>
                        ))}
                    </Slider>
                    {!isMobile && (
                        <div className="button-slide" style={{ right: "10px" }} onClick={() => handleNextClick()}>
                            <img src="/assets/images/icons/chevron-forward-front.svg" height={20} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Banner
import { Carousel } from 'antd'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { MainContext } from '../context/MainContext'
import { isMobile } from 'react-device-detect';

const CarouselImage = (props) => {
    const { current_idx } = useContext(MainContext)
    const [currentIdx, setCurrentIdx] = current_idx
    const [currSlide, setCurrSlide] = useState(0)
    const [nav1, setNav1] = useState()
    const [nav2, setNav2] = useState()
    let slider1 = useRef(null)
    let slider2 = useRef(null)
    const { data, setOpenPreview } = props
    const [listData] = useState([
        data.image_default !== "" ? data.image_default : undefined,
        data.image_front !== "" ? data.image_front : undefined,
        data.image_back !== "" ? data.image_back : undefined,
        data.image_side !== "" ? data.image_side : undefined
    ])

    useEffect(() => {
        setNav1(slider1.current)
        setNav2(slider2.current)
    }, [slider1, slider2])

    const onChangeSlide = (i) => {
        slider1.current?.goTo(i)
    }

    const setImageIdx = (i) => {
        setCurrSlide(i)
        setCurrentIdx(i)
    }

    return (
        <div className="detailimg_wrap" style={{ maxWidth: '25rem' }}>
            <div className="detail-gallery" style={{ width: "100%", border: '1px solid hsl(0, 0%, 93%)', borderRadius: '10px' }} >
                <Carousel
                    initialSlide={currSlide}
                    draggable
                    autoplaySpeed={5000}
                    swipeToSlide
                    asNavFor={nav2}
                    ref={slider1}
                    dots={false}
                    afterChange={(c) => {
                        onChangeSlide && onChangeSlide(c)
                        setCurrSlide(c)
                        setCurrentIdx(c)
                    }}
                >
                    {listData.map((el, i) => {
                        if (el !== undefined) {
                            return (
                                <div className="product-image-slider" style={{ width: "100%" }} key={i} onClick={() => !isMobile ? setOpenPreview(true) : null}>
                                    <img src={el} alt={data.name} className="img_product_detail" />
                                </div>
                            )
                        }
                    })}
                </Carousel>
                <Carousel
                    variableWidth
                    draggable
                    autoplaySpeed={5000}
                    swipeToSlide
                    dots={false}
                    ref={slider2}
                    asNavFor={nav1}
                    slidesToShow={5}
                    infinite={false}
                    focusOnSelect={true}
                >
                    {listData.map((x, index) => {
                        if (x !== undefined) {
                            return (
                                <div key={index} style={{ height: '65px', width: '65px', backgroundColor: 'green' }} onClick={() => setImageIdx(index)}>
                                    <img src={x} width='100%' height='100%' style={{ cursor: 'pointer', objectFit: 'cover', border: currSlide === index ? '1px solid #EC7825' : '' }} />
                                </div>
                            )
                        }
                    })}
                </Carousel>
            </div>
        </div>
    )
}

export default CarouselImage
const convertToIDR = (numb) => {
  if (numb !== undefined) {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert?.join(".").split("").reverse().join("");
    return rupiah;
  }
};

const capitalize = (str) => {
  if (str !== undefined) {
    let word = str.toLowerCase();
    return word.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
};

const uppercase = (str) => {
  if (str !== undefined) {
    return str.toUpperCase();
  }
};

const capitalizeContent = (str) => {
  if (str !== undefined) {
    let word = str.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
};

module.exports = {
  convertToIDR: convertToIDR,
  capitalize: capitalize,
  capitalizeContent: capitalizeContent,
  uppercase: uppercase,
};

import axios from "axios";
import React, { useContext, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MainContext } from "../context/MainContext";

const host = process.env.REACT_APP_BACKEND;
const menuOS = [
  {
    title: "Informasi Akun Official Store",
    path: "/menuofficialstore/info",
  },
  {
    title: "Daftar Member Saya",
    path: "/menuofficialstore/daftar-member",
  },
  {
    title: "Menu Penjualan",
    path: "/menuofficialstore/penjualan",
  },
  {
    title: "Riwayat Penjualan",
    path: "/menuofficialstore/riwayat-penjualan",
  },
  {
    title: "Riwayat Bonus",
    path: "/menuofficialstore/riwayat-bonus",
  },
];
const menuMember = [
  {
    title: "Informasi Akun Member",
    path: "/member/info",
  },
  {
    title: "Riwayat Pembelian",
    path: "/member/riwayat-pembelian",
  },
  {
    title: "Riwayat Bonus",
    path: "/member/riwayat-bonus",
  },
];
const menuAdmin = [
  {
    title: "Upload Data Member",
    path: "/menuadmin/upload-member",
  },
  {
    title: "Upload Data Bonus",
    path: "/menuadmin/upload-bonus",
  },
  {
    title: "Riwayat Penjualan",
    path: "/menuadmin/riwayat-penjualan",
  },
  {
    title: "Riwayat Bonus",
    path: "/menuadmin/riwayat-bonus",
  },
];

const Header = () => {
  const dataFetchedRef = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const {
    getProvinsi,
    auth_mode,
    id_kota,
    is_loading,
    provinsi_list,
    search_term,
    handleSearch,
    openSidebar,
    show_modal,
    dropdown_account,
  } = useContext(MainContext);
  const [searchTerm] = search_term;
  const [showModal, setShowModal] = show_modal;
  const [provinsi] = provinsi_list;
  const [isLoading, setIsLoading] = is_loading;
  const [idKota, setIdKota] = id_kota;
  const [showDropdownAccount, setShowDropdownAccount] = dropdown_account;
  const [authMode, setAuthMode] = auth_mode;
  let keyTemp = searchTerm.toLowerCase();
  let key = keyTemp.replace(/ /g, "-");

  const sectionMenu = [
    {
      title: "menu-admin",
      component: (
        <li
          className={`menu-category ${
            location.pathname.includes("/menuadmin") ? "active" : ""
          }`}
        >
          <p className="menu-title" style={{ marginBottom: "0" }}>
            Menu Admin
          </p>
          <div className="dropdown-panel menu-os" style={{ zIndex: 999 }}>
            {menuAdmin.length > 0 &&
              menuAdmin.map((el, i) => {
                return (
                  <ul className="dropdown-panel-list menu-os" key={i}>
                    <li className="menu-title">
                      <Link to={el.path}>{el.title}</Link>
                    </li>
                  </ul>
                );
              })}
          </div>
        </li>
      ),
    },
    {
      title: "menu-os",
      component: (
        <li
          className={`menu-category ${
            location.pathname.includes("/menuofficialstore") ? "active" : ""
          }`}
        >
          <p className="menu-title" style={{ marginBottom: "0" }}>
            Menu Official Store
          </p>
          <div className="dropdown-panel menu-os" style={{ zIndex: 999 }}>
            {menuOS.length > 0 &&
              menuOS.map((el, i) => {
                return (
                  <ul className="dropdown-panel-list menu-os" key={i}>
                    <li className="menu-title">
                      <Link to={el.path}>{el.title}</Link>
                    </li>
                  </ul>
                );
              })}
          </div>
        </li>
      ),
    },
    {
      title: "menu-member",
      component: (
        <li
          className={`menu-category ${
            location.pathname.includes("/member") ? "active" : ""
          }`}
        >
          <p className="menu-title" style={{ marginBottom: "0" }}>
            Member
          </p>
          <div className="dropdown-panel menu-os" style={{ zIndex: 999 }}>
            {menuMember.length > 0 &&
              menuMember.map((el, i) => {
                return (
                  <ul className="dropdown-panel-list menu-os" key={i}>
                    <li className="menu-title">
                      <Link to={el.path}>{el.title}</Link>
                    </li>
                  </ul>
                );
              })}
          </div>
        </li>
      ),
    },
  ];

  const navigateTo = (path) => {
    history.push(path);
  };

  const navigateOS = (slugKota) => {
    axios.get(`${host}/api/v1/kota/?search=${slugKota}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          let id = res.data[0].id;
          setIdKota(id);
        }
      }
    });
  };

  const gotoAuth = (mode) => {
    const token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    if (token.access) {
      if (token.isAdmin) {
        history.push("/menuadmin/riwayat-penjualan");
      } else if (!token.isAdmin && token.isOs) {
        history.push("/menuofficialstore/info");
      } else {
        history.push("/member/info");
      }
    } else {
      setAuthMode(mode);
      setShowModal(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchProduct();
  };

  const searchProduct = () => {
    if (searchTerm !== "") {
      history.push({
        pathname: "/search",
        search: `?q=${key}`,
      });
    }
  };

  const logout = () => {
    if (location.pathname === "/") {
      setIsLoading(true);
      setTimeout(() => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("MemberCode");
        localStorage.removeItem("IdMember");
        setIsLoading(false);
      }, 500);
    } else {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("MemberCode");
      localStorage.removeItem("IdMember");
      history.push("/");
    }
  };

  const displayMenu = () => {
    const token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    let comp = [];
    if (token.access) {
      if (token.isAdmin) {
        comp.push(sectionMenu[0]);
      } else if (!token.isAdmin && token.isOs) {
        comp.push(sectionMenu[1]);
      } else {
        comp.push(sectionMenu[2]);
      }
      return (
        <>
          {comp.map((el, i) => (
            <div key={i}>{el.component}</div>
          ))}
        </>
      );
    }
  };

  const toggleAccount = () => {
    setShowDropdownAccount(!showDropdownAccount);
  };

  const displayAccount = () => {
    const token = localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : [];
    if (token) {
      let username = token.username;
      if (username) {
        return <button className="link-account">{username}</button>;
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getProvinsi();
  }, []);

  return (
    <header>
      <div className="header-main no-print">
        <div className="container">
          <div
            className="header-logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigateTo("/")}
          >
            <img src="/assets/images/LOGO.png" style={{ height: "40px" }} />
          </div>
          <div className="header-search-container">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="search"
                autocomplete="off"
                className="search-field"
                value={searchTerm}
                placeholder={`Cari barang disini`}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <button className="search-btn" style={{ marginRight: "4px" }}>
                <img src="/assets/images/icons/search.svg" height={17} />
              </button>
            </form>
          </div>
          {localStorage.getItem("isLoggedIn") ? (
            <div className="dropdown" onClick={() => toggleAccount()}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evently",
                }}
              >
                <img src="/assets/images/icons/person-circle.svg" height={25} />
                {displayAccount()}
              </div>
              <div
                className={`dropdown-account ${
                  showDropdownAccount ? "active" : ""
                }`}
              >
                <p onClick={() => logout()}>Keluar</p>
              </div>
            </div>
          ) : (
            <div className="header-user-actions">
              <div className="action-btn">
                <div className="btn-auth-wrap">
                  <div
                    title="Masuk"
                    className="btn-auth-register"
                    onClick={() => gotoAuth("signin")}
                  >
                    <p
                      className="font-md"
                      style={{ color: "#fff", fontWeight: "500" }}
                    >
                      Masuk
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <nav className="desktop-navigation-menu">
        <div className="container">
          <ul className="desktop-menu-category-list">
            <li
              className={`menu-category ${
                location.pathname === "/" ? "active" : ""
              }`}
              onClick={() => navigateTo("/")}
            >
              <p className="menu-title">Tentang Kami</p>
            </li>
            <li
              className={`menu-category ${
                location.pathname.includes("/officialstore") ? "active" : ""
              }`}
            >
              <p className="menu-title" style={{ marginBottom: "0" }}>
                Official Store
              </p>
              <div className="dropdown-panel" style={{ zIndex: 999 }}>
                {provinsi.length > 0 &&
                  provinsi.map((el, i) => {
                    if (el.kota.length !== 0) {
                      return (
                        <ul className="dropdown-panel-list" key={i}>
                          <li className="menu-title">
                            <a href={`/officialstore/${el.slug}/`}>{el.nama}</a>
                          </li>
                          {el.kota.length > 0 &&
                            el.kota.map((item, idx) => {
                              return (
                                <li
                                  className="panel-list-item"
                                  key={idx}
                                  onClick={() => navigateOS(item.slug)}
                                >
                                  <Link
                                    to={`/officialstore/${el.slug}/${item.slug}`}
                                  >
                                    {item.nama}
                                  </Link>
                                </li>
                              );
                            })}
                          <li className="panel-list-item"></li>
                        </ul>
                      );
                    }
                  })}
              </div>
            </li>
            <li
              className={`menu-category ${
                location.pathname.includes("/product") ? "active" : ""
              }`}
              onClick={() => navigateTo("/product")}
            >
              <p className="menu-title">Produk</p>
            </li>
            {displayMenu()}
          </ul>
        </div>
      </nav>
      <div className="mobile-bottom-navigation no-print">
        <div
          className="action-btn"
          onClick={() => openSidebar()}
          title="Sidebar"
        >
          <img src="/assets/images/icons/menu-outline.svg" height={27} />
        </div>
        <div
          onClick={() => navigateTo("/")}
          className="action-btn"
          title="About"
        >
          {location.pathname === "/" ? (
            <img src="/assets/images/icons/heart.svg" height={27} />
          ) : (
            <img src="/assets/images/icons/heart-outline.svg" height={27} />
          )}
        </div>
        <div
          onClick={() => navigateTo("/product")}
          className="action-btn"
          title="Catalog"
        >
          {location.pathname.includes("/product") ? (
            <img src="/assets/images/icons/bag-handle.svg" height={27} />
          ) : (
            <img
              src="/assets/images/icons/bag-handle-outline.svg"
              height={27}
            />
          )}
        </div>
        <div
          onClick={() => gotoAuth("signin")}
          className="action-btn"
          title="Member"
        >
          {location.pathname.includes("/menuofficialstore/info") ||
          location.pathname.includes("/member/info") ||
          location.pathname.includes("/menuadmin/riwayat-penjualan") ? (
            <img src="/assets/images/icons/person.svg" height={27} />
          ) : (
            <img src="/assets/images/icons/person-outline.svg" height={27} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;

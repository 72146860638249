import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button, Card, Col, Modal, Row } from "antd";
import moment from "moment";
import React from "react";
import { convertToIDR } from "../Helper";
import { PrintIcon } from "./icons";
import Invoice from "./Invoice";

const ModalHistoryMobile = (props) => {
  const { isVisible, onCancel } = props;
  return (
    <Modal
      title={`Pembelian SO00${props.cartId}`}
      visible={isVisible}
      onCancel={onCancel}
      width={1000}
      centered
      footer={false}
    >
      {props.data &&
        props.data.map((el, i) => (
          <Card
            key={i}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Row>
              <Col span={10}>
                <img src={el.product_image} height={100} />
              </Col>
              <Col span={12} style={{ marginLeft: "10px" }}>
                <Row>
                  <p style={{ fontWeight: 600 }}>{el.product_name}</p>
                  <p>
                    Harga :{" "}
                    <span style={{ fontWeight: 600, color: "#457b9d" }}>
                      {convertToIDR(el.product_price)}
                    </span>
                  </p>
                  <p>
                    Harga Bonus :{" "}
                    <span style={{ fontWeight: 600, color: "#457b9d" }}>
                      {convertToIDR(el.product_price_bonus)}
                    </span>
                  </p>
                  <p>
                    Jumlah :{" "}
                    <span style={{ fontWeight: 600, color: "#538d22" }}>
                      {el.qty} item
                    </span>
                  </p>
                  <p>
                    Subtotal :{" "}
                    <span style={{ fontWeight: 600, color: "#90323d" }}>
                      {convertToIDR(el.total)}
                    </span>
                  </p>
                  <p>
                    Subtotal Bonus :{" "}
                    <span style={{ fontWeight: 600, color: "#90323d" }}>
                      {convertToIDR(el.total_bonus)}
                    </span>
                  </p>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
      <div>
        <PDFDownloadLink
          document={
            <Invoice
              data={props.data}
              tglBeli={props.tglBeli}
              grandTotal={props.grandTotal}
              grandTotalBonus={props.grandTotalBonus}
              nama={props.nama}
              namaOS={props.namaOS}
              status={props.status}
            />
          }
          fileName={`Invoice ${moment()
            .format("D MMMM YYYY HH.mm")
            .toString()}`}
        >
          {({ loading }) =>
            loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    height: "35px",
                    width: "90px",
                    fontSize: "15.5px",
                    display: "flex",
                    width: "110px",
                    alignItems: "center",
                  }}
                  disabled
                  type="primary"
                >
                  <PrintIcon />{" "}
                  <span style={{ marginLeft: "10px" }}>loading...</span>
                </Button>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    height: "35px",
                    width: "90px",
                    fontSize: "15.5px",
                    display: "flex",
                    width: "110px",
                    alignItems: "center",
                  }}
                  type="primary"
                >
                  <PrintIcon />{" "}
                  <span style={{ marginLeft: "10px" }}>Cetak</span>
                </Button>
              </div>
            )
          }
        </PDFDownloadLink>
      </div>
    </Modal>
  );
};

export default ModalHistoryMobile;
